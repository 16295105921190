// Bullet point
//
//

.o-square-list {
  padding-left: 0;
  color: theme-color("primary");
  text-align: left;

  &,
  ul * {
    list-style-type: none;
  }

  li {
    color: #000;

    &::before {
      margin-right: 1rem;
      font-size: $font-size-sm;
      line-height: 1.25rem;
      color: theme-color("primary");
      vertical-align: text-top;
      content: "\25A0";
    }

    span {
      display: inline-block;
      margin-bottom: .625rem;
    }

    > ul {
      padding-left: 1rem;
    }
  }

  ul > li::before {
    color: $gray-600;
  }

  ul ul > li::before { // stylelint-disable-line selector-max-type
    color: $gray-500;
  }
}
