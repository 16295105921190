// Orange navbar
//
//
// stylelint-disable selector-no-qualifying-type
// stylelint-disable selector-max-class
// stylelint-disable selector-max-compound-selectors
// stylelint-disable selector-max-combinators

.fixed-header {
  position: fixed;
  top: 0;
  z-index: $zindex-fixed;
  width: 100%;
}

.navbar {
  .nav-link.icon {
    margin-top: 0;

    span[class^="icon-"] {
      font-size: 1.5rem;
      font-weight: $font-weight-normal;
    }

    &[class*="svg-"],
    &[class*="svg-"]::after {
      width: 1.875rem;
      height: 1.875rem;
    }
  }

  .nav-link:not(.icon)[class*="svg-"]::after {
    top: 0;
    right: 0;
    left: auto;
  }
}

.navbar.supra {
  @include media-breakpoint-down(xs) {
    display: none;
  }

  height: $navbar-supra-height; // IE flex support
  min-height: $navbar-supra-height;
  padding-top: 0;
  padding-bottom: 0;
  border-bottom: 1px solid $gray-900;

  > .container,
  > .container .navbar-nav {
    align-items: center;
  }

  .navbar-brand {
    display: none;
  }

  .navbar-nav {
    margin: 0;

    > .nav-link:first-child,
    .nav-item:first-child .nav-link {
      margin-left: 0;
    }
  }

  .nav-link,
  .dropdown-toggle {
    @include focus {
      outline-offset: .5rem;
    }

    font-size: $font-size-sm;
    line-height: 1;

    span[class^="icon-"] {
      font-size: $h5-font-size;
      font-weight: $font-weight-normal;
    }
  }

  .nav-link.icon {
    span[class^="icon-"] {
      font-size: $h6-font-size;
      font-weight: $font-weight-normal;
    }

    &[class*="svg-"],
    &[class*="svg-"]::after {
      width: 1.25rem;
      height: 1.25rem;
    }
  }

  .dropdown-menu {
    right: 0;
    left: auto;
  }
}

.navbar-expand {
  @each $breakpoint in map-keys($grid-breakpoints) {
    $next: breakpoint-next($breakpoint, $grid-breakpoints);
    $infix: breakpoint-infix($next, $grid-breakpoints);

    &#{$infix} {
      @include media-breakpoint-down($breakpoint) {
        &,
        &.minimized {
          .mega-menu .nav-link {
            padding-right: 0;
            outline-offset: -1px;

            &[data-toggle="collapse"]::before {
              margin-top: .3125rem;
              margin-right: .9375rem;
              content: "";
              border-top: .4375em solid;
              border-right: .4375em solid transparent;
              border-left: .4375em solid transparent;
              transform: rotateZ(180deg);
            }

            &[data-toggle="collapse"].collapsed::before {
              transform: rotateZ(0deg);
            }
          }

          .mega-menu-panel .nav-link.back {
            display: block;

            &::before {
              float: left;
              transform: rotateZ(180deg);
            }
          }

          .mega-menu-panel > .container {
            width: 100%;
            padding: 0;
            margin: 0;

            a[data-toggle="collapse"] {
              display: none;
            }

            > .navbar-nav > .nav-item {
              padding: 0;
              border: 0;
            }

            > .navbar-nav .navbar-nav .nav-item:last-child {
              border-bottom: 1px solid #333;
            }
          }
        }
      }

      @include media-breakpoint-up($next) {
        .mega-menu {
          overflow: visible;

          > .navbar-nav {
            transition: none;
            transform: none;

            .nav-link::before {
              display: none;
            }
          }
        }

        .mega-menu-panel {
          position: absolute;
          top: 100px;
          left: 0;
          z-index: $zindex-fixed;
          width: 100%;
          padding: 1rem 0;
          background-color: #000;
          border-top: 1px solid #333;

          a[data-toggle="collapse"] {
            display: block;
            float: right;
            margin: 1.25rem;
            color: #fff;
          }

          .nav-link {
            margin-left: 0;
          }

          .svg-delete {
            width: 1.25rem;
            height: 1.25rem;
          }

          > .container > .navbar-nav {
            flex-direction: row;
            align-items: flex-start;
          }

          > .container > .navbar-nav > .nav-item {
            flex-grow: 1;
            padding: 1rem 0;
          }

          > .container > .navbar-nav > .nav-item > .nav-link {
            padding: 0;
            margin-right: .625rem;
            margin-bottom: 1rem;
            font-size: 1.25rem;
          }

          > .container > .navbar-nav .navbar-nav {
            position: static;
            display: block !important;  // stylelint-disable-line declaration-no-important
            flex-direction: column;
            align-items: flex-start;
          }

          > .container > .navbar-nav .navbar-nav .nav-link {
            padding: 0;
            line-height: 1.25rem;
            outline-offset: 1px;
          }

          > .container > .navbar-nav .nav-item:first-child,
          > .container > .navbar-nav .nav-item + .nav-item {
            margin-left: 0;
          }

          > .container > .navbar-nav .nav-link.back {
            display: none;
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .navbar-brand,
  .navbar-brand + .navbar-brand {
    align-self: center;
  }
}
