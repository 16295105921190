//
// dropdown
//

.dropdown-toggle::after {
  margin-left: .625rem;
  vertical-align: 10%;
}

.dropdown-item {
  padding-left: 1.25rem;
  font-weight: $font-weight-bold;

  &:focus {
    color: #000;
    background-color: #fff;
    outline: 1px dotted;
  }

  &:focus:hover {
    color: #fff;
    background-color: #000;
  }
}
