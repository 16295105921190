//
// Tables
//
// stylelint-disable selector-no-qualifying-type
// stylelint-disable selector-max-type

// Change the table states color
@mixin table-row-variant-border($state, $color) {
  // Exact selectors below required to override `.table-striped` and prevent
  // inheritance to nested tables.
  .table > thead > tr,
  .table > tbody > tr,
  .table > tfoot > tr {
    > td.#{$state},
    > th.#{$state},
    &.#{$state} > td,
    &.#{$state} > th {
      background-color: $color;
    }
  }

  // Hover states for `.table-hover`
  // Note: this is not available for cells or rows within `thead` or `tfoot`.
  .table-hover > tbody > tr {
    > td.#{$state}:hover,
    > th.#{$state}:hover,
    &.#{$state}:hover > td,
    &:hover > .#{$state},
    &.#{$state}:hover > th {
      background-color: darken($color, 5%);
    }
  }
}

.table {
  // Cells
  > thead,
  > tbody,
  > tfoot {
    > tr {
      border-bottom: 1px solid $table-border-color;

      > th,
      > td {
        font-size: $font-size-sm;
        line-height: 1.25rem;
        vertical-align: middle;
        border: 0;
      }

      > th:first-child,
      > td:first-child {
        padding-left: .625rem;
      }

      [class^="icon-"],
      [class*=" icon-"] {
        font-size: 1.8125rem;
      }

      [class^="svg-"] {
        display: block;
        width: 1.8125rem;
        height: 1.8125rem;
        margin: 0 auto;
      }

      [class^="svg-"]::after {
        width: 1.8125rem;
        height: 1.8125rem;
      }
    }
  }

  thead {


    > tr {
      border-bottom-width: 2px;

      > th {
        font-size: $font-size-base;
        font-weight: $font-weight-bold;
      }
    }
  }

  tbody > th {
    font-weight: $font-weight-normal;
  }

  &.has-checkbox {
    tr.selected {
      > td,
      > th {
        background-color: $gray-400;
      }
    }

    tr > th:first-child,
    tr > td:first-child {
      width: 1.25rem;
      padding-top: 0;
      padding-bottom: 0;

      .custom-control.custom-checkbox {
        padding-top: 0;
        padding-left: 1.25rem;
        margin: 0;
        vertical-align: middle;
      }
    }
  }

  &.has-icon {
    tr > td,
    tr > th {
      height: 3.1875rem;
      padding-top: .6875rem;
      vertical-align: middle;

      span,
      img {
        vertical-align: middle;
      }

      img {
        width: 1.875rem;
        height: 1.875rem;
        margin-right: .625rem;
      }

      [class^="icon-"] {
        width: 1.875rem;
        margin-right: .375rem;
        font-size: 1.8125rem;
        line-height: 0;
        vertical-align: middle;
      }
    }
  }

  // &.has-status {
  //   thead th:not(:first-child),
  //   tr > td {
  //     text-align: center;
  //   }
  // }

  &.table-reflow {
    thead,
    tbody, {
      > tr {
        border: 0;
      }

      > tr > td {
        line-height: 1.25rem;
      }

      > tr > th:first-child {
        border-bottom: 2px solid $table-border-color;
      }

      > tr > th,
      > tr > td {
        border-bottom: 1px solid $table-border-color;
      }

      > tr > td:last-child {
        border-right: 0;
      }
    }

    thead > tr {
      border: 0;

      > th:not(:first-child) {
        padding-left: .625rem;
        border-bottom: 1px solid $table-border-color;
      }

      > th:last-child {
        border-right: 0;
      }
    }

    tbody {
      > tr > th:first-child {
        padding-left: 0;
      }
    }
  }
}

@include table-row-variant-border(success, theme-color("success"));
@include table-row-variant-border(info, theme-color("info"));
@include table-row-variant-border(warning, theme-color("warning"));
@include table-row-variant-border(danger, theme-color("danger"));


// force caption for tables
caption {
  padding: .75rem 0;
  font-size: $h1-font-size;
  font-weight: $font-weight-bold;
  color: #000;
  caption-side: top;
  background-color: #fff;
}

// Style jquery tablesorter ext
// scss-lint:disable SelectorFormat
//
// Table sorter
//

.tablesorter {
  caption {
    padding: .75rem 0;
    font-size: $h1-font-size;
    font-weight: $font-weight-bold;
    color: #000;
    background-color: #fff;
  }

  th {
    padding-right: 30px;
    text-align: left;
    cursor: pointer;

    &.header {
      position: relative;
    }

    .tablesorter-header-inner span {
      padding-right: 1.6875rem;
    }

    &.tablesorter-headerDesc .tablesorter-header-inner span::after {
      position: absolute;
      top: 45%;
      width: .875rem;
      height: .4375rem;
      margin-left: .625rem;
      content: "";
      background-image: $accordion-arrow-icon;
      transform: rotate(180deg);
    }

    &.tablesorter-headerAsc .tablesorter-header-inner span::after {
      position: absolute;
      top: 45%;
      width: .875rem;
      height: .4375rem;
      margin-left: .625rem;
      content: "";
      background-image: $accordion-arrow-icon;
    }

    &.tablesorter-headerUnSorted {
      .tablesorter-header-inner:hover span::after,
      .tablesorter-header-inner:focus span::after {
        position: absolute;
        top: 31%;
        width: .875rem;
        height: 1.0625rem;
        margin-left: .625rem;
        content: "";
        background-image: $o-sortable-hover-icon;
      }
    }

    a {
      color: #000;
      text-decoration: none;

      @include hover-focus {
        color: theme-color("primary");
        text-decoration: underline;
      }
    }
  }

  td {
    text-align: left;
  }
}
