// Base nav
.nav {
  .nav-item:first-child {
    margin-left: - ($spacer * .5);
  }

  &.flex-column .nav-item:first-child {
    margin-left: 0;
  }

  .nav-link {
    padding: $nav-link-padding-y ($nav-link-padding-x * .5);
    line-height: 1.25rem;
  }
}

//
//    classic tab
//

.nav-tabs {
  .nav-item {
    &:first-child {
      margin-left: 0;
    }

    + .nav-item {
      margin-left: .5rem;
      // On mobile no margin between tabs
      @include media-breakpoint-down(md) {
        margin-left: 0;
      }
    }
  }

  .nav-link:not(.disabled) {
    &,
    &.dropdown-toggle {
      @include focus {
        outline-width: 1px;
        outline-style: dotted;
        outline-offset: -6px;
      }
    }

    + .nav-link {
      margin-left: .5rem;
      // On mobile no margin between tabs
      @include media-breakpoint-down(md) {
        margin-left: 0;
      }
    }
  }
}

// Responsive tabs
//
//

.o-tab-container {
  display: flex;
  flex-flow: row wrap;

  .o-tab-heading {
    position: relative;
    display: inline-block;
    padding: .625rem 1.25rem;
    margin-left: .5rem;
    font-weight: $font-weight-bold;
    color: #000;
    text-align: center;
    background-color: #fff;
    border: 0;
    border-bottom: 2px solid #000;

    &:first-child {
      margin-left: 0;
      border-top: 0;
    }

    &[aria-expanded="false"] {
      border-bottom: 2px solid #000;
    }

    &[aria-expanded="true"] {
      padding-top: .5rem;
      border: 2px solid #000;
      border-bottom: 0;
    }

    &:focus {
      outline-offset: -4px;
    }

    &:hover:not([aria-expanded="true"]) {
      color: #fff;
      background-color: #000;
    }
  }

  .o-tab-content {
    display: none;
    align-self: flex-end;
    order: 1;
    width: 100%;
    padding: .625rem 1.25rem;
    background-color: #fff;
    box-shadow: 0 -2px 0 #000;

    &.show {
      display: block;
    }
  }

  @include media-breakpoint-down(xs) {
    .o-tab-heading {
      flex-grow: 1;
      margin-left: 0;
      text-align: center;

      &[aria-expanded="false"] {
        color: #fff;
        background-color: #666;

        &:active {
          color: #fff;
          background-color: #f16e00;
        }
      }
    }

    &.accordion-layout {
      display: block;

      .o-tab-heading {
        position: relative;
        display: block;
        padding: .9375rem 0;
        margin-left: 0;
        font-weight: $font-weight-bold;
        color: #000;
        text-align: left;
        background-color: #fff;
        border: 0;

        &:focus {
          outline-offset: 0;
        }

        &:hover {
          color: #f16e00;
          background-color: #fff;
        }

        &[aria-expanded="false"] {
          border-bottom: 1px #ccc solid;
        }

        &[aria-expanded="true"]::after {
          transform: rotate(180deg);
        }

        &:first-child {
          margin-left: 0;
          border-top: 1px solid #ccc;
        }

        &::after {
          position: absolute;
          top: 1.375rem;
          right: .625rem;
          width: .875rem;
          height: .4375rem;
          content: "";
          background-image: url("data:image/svg+xml;charset=utf8, %3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2014%207%22%3E%3Cpath%20d%3D%22M7%207L0%200h14L7%207z%22%2F%3E%3C%2Fsvg%3E");
        }
      }

      .o-tab-content {
        padding-left: 0;
        border-bottom: 1px #ccc solid;
        box-shadow: none;
      }
    }
  }
}

// Pills
// -------------------------
.nav-pills {
  &.nav-stacked .nav-item {
    margin: 0;

    + .nav-item {
      margin-left: 0;
    }
  }

  .nav-item {
    &:first-child {
      margin-left: 0;
    }

    + .nav-item {
      margin-left: ($grid-gutter-width / 4);
    }
  }

  .nav-link {
    @include active {
      background-color: $nav-pills-link-active-bg;
    }

    @include hover {
      color: $nav-pills-link-active-color;
      cursor: default;
      background-color: $nav-pills-link-active-bg;
      &.disabled {
        color: $nav-link-disabled-color;
        background-color: $nav-link-disabled-hover-bg;
      }
    }

    @include focus {
      color: $nav-pills-focus-link-color;
      background-color: $nav-pills-focus-link-bg;
      outline: none;
      &.disabled {
        color: $nav-link-disabled-color;
        background-color: $nav-link-disabled-hover-bg;
      }
    }

    padding-top: $spacer;
    padding-bottom: $spacer;
  }
  .dropdown-menu {
    right: 0;
    left: auto;

    .nav-item {
      margin: 0;

      .nav-link {
        @include focus {
          outline: 1px dotted #000;
          outline-offset: -2px;
        }
        display: block;
        padding: .625rem .625rem .625rem 1.25rem;
        margin: 0;
        color: #000;
        background: #fff;
        border: 0;
      }

      .nav-link:hover {
        color: #fff;
        background: #000;
      }
    }
  }
}

.tab-content {
  padding: 1.125rem;
  border-color: #000;
  border-style: solid;
  border-width: 0 2px 2px;

  .tab-pane p {
    margin-bottom: 0;
  }
}

.tab-content-clear {
  // padding: 1.125rem;
  border: 0;
}

// breadcrums

.breadcrum {
  line-height: 1;
}

// priority nav

.overflow-nav {
  display: none;
}

.o-nav-local.resizing {
  overflow: hidden;
}

.hide {
  display: none !important; // stylelint-disable-line declaration-no-important
}

.show-inline-block {
  display: inline-block !important; // stylelint-disable-line declaration-no-important
}

.overflow-nav-list {
  .nav-link {
    border: 0;
    &:focus {
      outline-offset: -2px;
    }
  }

  .nav-item:last-child {
    margin-bottom: 0;
  }
}

.o-nav-local .overflow-nav-list {
  margin-top: 1px;
  border: 0;
  box-shadow: 0 .3125rem .625rem -.375rem #999;
}
