//
// Orange stepbar
//
// stylelint-disable selector-max-class

// url encoded colors
$arrow-black: "000000" !default;
$arrow-gray: "dddddd" !default;
$arrow-orange: "f16e00" !default;

// generate url encoded svg with color
@function arrow-svg ($color) {
  @return "%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2240%22%20viewBox%3D%220%200%2016%2040%22%3E%3Cpath%20d%3D%22M0%200l14%2020L0%2040z%22%20fill%3D%22%23#{$color}%22%2F%3E%3Cpath%20d%3D%22M2%200H0l14%2020L0%2040h2l14-20z%22%20fill%3D%22%23fff%22%2F%3E%3C%2Fsvg%3E";
}

@mixin stepbar-arrow ($color) {
  &::after {
    position: absolute;
    top: 0;
    right: -$o-stepbar-arrow-width;
    z-index: 500;
    display: block;
    width: $o-stepbar-arrow-width;
    height: $o-stepbar-height;
    content: "";
    $arrow-svg: arrow-svg($color);
    background-image: url("data:image/svg+xml,#{$arrow-svg}");
    background-size: $o-stepbar-arrow-width $o-stepbar-height;
  }
}

.o-stepbar {
  &,
  ol,
  ul {
    padding: 0;
    margin: 0;
    overflow: hidden;
  }

  // one item
  .stepbar-item:first-child:nth-last-child(1) {
    width: 100%;
  }

  // two items
  .stepbar-item:first-child:nth-last-child(2),
  .stepbar-item:first-child:nth-last-child(2) ~ .stepbar-item {
    width: 50%;
  }

  // three items
  .stepbar-item:first-child:nth-last-child(3),
  .stepbar-item:first-child:nth-last-child(3) ~ .stepbar-item {
    width: 33.3333%;
  }

  // four items
  .stepbar-item:first-child:nth-last-child(4),
  .stepbar-item:first-child:nth-last-child(4) ~ .stepbar-item {
    width: 25%;
  }

  // five items
  .stepbar-item:first-child:nth-last-child(5),
  .stepbar-item:first-child:nth-last-child(5) ~ .stepbar-item {
    width: 20%;
  }

  .stepbar-item {
    @include stepbar-arrow($arrow-gray);
    position: relative;
    float: left;
    padding: ($spacer / 2) $spacer;
    padding-left: 2rem;
    font-size: $font-size-base;
    font-weight: $font-weight-bold;
    line-height: $line-height-base;
    color: #000;
    white-space: nowrap;
    list-style: none;
    background-color: $gray-400;

    &:first-child {
      padding-left: $spacer;
    }

    &:last-child {
      &::after {
        display: none;
      }
    }

    &.done {
      @include stepbar-arrow($arrow-black);
      background-color: #000;
      .stepbar-link {
        color: #fff;
      }

      &:last-child::after {
        display: none;
      }
    }

    &.current {
      @include stepbar-arrow($arrow-orange);
      background-color: theme-color("primary");
      .stepbar-link {
        color: #fff;
      }

      &:last-child::after {
        display: none;
      }
    }

    .stepbar-link {
      text-decoration: none;

      .step-number::after {
        content: ".";
      }
    }
  }
}

// For mobile display in any case, reduce the padding and the height
@include media-breakpoint-down(xs) {
  .o-stepbar {
    .stepbar-item {
      padding: $o-stepbar-mobile-padding-vertical ($spacer / 2) $o-stepbar-mobile-padding-vertical ($spacer + $o-stepbar-mobile-arrow-width);
      line-height: $input-btn-line-height;
    }

    .stepbar-item::after,
    .stepbar-item.done::after,
    .stepbar-item.current::after,
    .stepbar-item.next::after {
      right: -($o-stepbar-mobile-arrow-width - .0625); // remove 1px for mobile display error
      width: $o-stepbar-mobile-arrow-width;
      height: $o-stepbar-mobile-arrow-height;
      background-size: cover;
    }

    &.xs-numbers {
      .step-number::after {
        display: none;
      }

      // three items
      .stepbar-item:first-child:nth-last-child(3),
      .stepbar-item:first-child:nth-last-child(3) ~ .stepbar-item {
        width: 20%;

        &.current {
          width: 60%;
        }
      }

      // four and five items
      .stepbar-item:first-child:nth-last-child(4),
      .stepbar-item:first-child:nth-last-child(4) ~ .stepbar-item,
      .stepbar-item:first-child:nth-last-child(5),
      .stepbar-item:first-child:nth-last-child(5) ~ .stepbar-item {
        width: 20%;

        &.current {
          width: 40%;
        }
      }

      .stepbar-item {
        width: 20%;
        padding-right: 0;
        padding-left: $o-stepbar-mobile-arrow-width;
        text-align: center;

        &:first-child {
          padding-left: 0;
        }

        &.current {
          width: 60%;
        }

        &.current .step-number {
          display: none;
        }

        &:not(.current) .step-title {
          display: none;
        }
      }
    }

    &.xs-labels {
      .step-number {
        display: none;
      }
    }
  }
}
