//
//  Orange forms
//
// stylelint-disable selector-no-qualifying-type
// stylelint-disable selector-max-class

.form-group {
  input[type="radio"],
  input[type="checkbox"] {
    width: auto;
  }

  &.row {
    .form-control-feedback {
      padding-left: 0;
    }

    &.has-danger.top {
      flex-direction: row;

      label,
      .col-form-label {
        order: 1;
      }

      .form-control {
        order: 2;
        margin-top: 0;
      }

      .form-control-feedback {
        order: 0;
      }
    }

    &.has-danger.right {
      label,
      .col-form-label {
        min-width: auto;
      }

      .form-control-feedback {
        align-self: center;
        margin-top: 0;
      }
    }
  }

  &.is-required .col-form-label::after {
    margin-left: 6px;
    font-weight: $font-weight-bold;
    color: theme-color("primary");
    content: "*";
  }

  // browser reset
  .form-control:invalid {
    outline: none;
    box-shadow: none;
  }

  &.has-danger,
  &.has-warning,
  &.has-success {
    .col-form-label {
      color: #000;
    }
  }

  .form-control-feedback {
    margin-top: .625rem;
    font-size: $font-size-sm;
    font-weight: $font-weight-normal;
    line-height: 1;
  }

  &.has-danger {
    position: relative;

    .form-control-feedback {
      margin-top: .8125rem;
      font-weight: $font-weight-bold;
      color: theme-color("danger");
    }

    .form-control-danger {
      background-image: none;
    }

    &.top {
      display: flex;
      flex-direction: column;

      .col-form-label {
        margin-bottom: 0;
      }

      .form-control {
        order: 2;
      }

      .form-control-feedback {
        order: 1;
        margin-top: 0;
        margin-bottom: .8125rem;
      }
    }

    &.right {
      display: flex;
      flex-flow: row wrap;

      label,
      .col-form-label {
        min-width: 100%;
      }

      .form-control-message,
      .form-control-feedback {
        margin-left: .625rem;
      }
    }
  }
}

select.form-control {
  &:not([size]):not([multiple]) {
    height: auto;
    padding-top: .4375rem;
    padding-bottom: .4375rem;

    &.form-control-lg {
      padding-top: .65625rem;
      padding-bottom: .65625rem;
    }

    &.form-control-sm {
      padding-top: .1875rem;
      padding-bottom: .1875rem;
    }
  }
}

// Reposition feedback icon if input has visible label above
.has-feedback label {
  ~ .form-control-feedback {
    top: ($line-height-base + .5); // Height of the `label` and its margin
  }
}
