//
// Cards
//

.card-deck .o-card-link {
  margin: 0 1rem;
}

.o-card-link {
  .card-title {
    align-self: center;
    width: 100%; // IE fix
    margin: auto 0;
    font-weight: $font-weight-bold;
    word-break: normal;
  }

  .card-img {
    padding: 0;
    text-align: center;

    img {
      width: 100%;
      height: auto;
    }
  }

  .card-body {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
}

@include media-breakpoint-up(xs) {
  .card-deck {
    margin: 0;
  }
}

@include media-breakpoint-down(xs) {
  .card.o-card-link {
    flex-basis: calc(50% - 2rem);
    margin: 1rem;
  }

  .card-deck {
    flex-direction: column;
  }

  .card-deck .o-card-link,
  .o-card-link {
    flex-direction: row;
    height: 3.75rem;
    margin: 0;
    margin-bottom: .625rem;
    border: 0;
  }

  .o-card-link {
    .card-img {
      flex-basis: 20%;
      width: auto;
      padding: 0;

      img {
        width: auto;
        height: 3.75rem;
      }
    }

    .card-body {
      flex-basis: 80%;
      align-self: center;
      padding: 0 1rem;
      text-align: left;
    }

    .card-title {
      align-self: flex-start;
      margin: 0;

      &::after {
        position: absolute;
        top: calc(50% - .5rem);
        right: .25rem;
        width: 1rem;
        height: 1rem;
        content: "";
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 15 24'%3E%3Cpath d='M3 0L0 3l9 9-9 9 3 3 12-12' fill='%23999'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
      }
    }
  }
}

@include media-breakpoint-only(sm) {
  .card.o-card-link {
    flex-basis: calc(50% - 2rem);
    margin: 1rem;
  }
}
