// stylelint-disable selector-no-qualifying-type
.modal {

  .modal-content {
    display: flex;
    flex-direction: column;
    padding: 2.5rem;
    border: 0;
  }

  .modal-header {
    position: relative;
    min-height: 1.25rem;
    padding: 0;
    margin-bottom: 2.5rem;
    border: 0;

    .btn {
      position: absolute;
      top: -.625rem;
      right: -.625rem;
      padding: .625rem;
      background-color: transparent;
      border: 0;


      .close {
        width: 1.25rem;
        height: 1.25rem;
        margin: 0;
        background-image: url("data:image/svg+xml;charset=utf8, %3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22852.063%22%20height%3D%22852.063%22%20viewBox%3D%220%200%20852.063%20852.063%22%3E%3Cpath%20id%3D%22closing_cross.svg%22%20data-name%3D%22closing%20cross.svg%22%20class%3D%22cls-1%22%20d%3D%22M.987%2057.125L57.125.987%20851.048%20794.91l-56.138%2056.138zM794.91.987l56.138%2056.138L57.125%20851.048.987%20794.91z%22%20fill%3D%22%23666%22%2F%3E%3C%2Fsvg%3E");
        background-size: 1.25rem;
        opacity: 1;
      }
    }
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    padding: 0;
    border: 0;

    .card,
    .card {
      display: flex;
      flex-direction: row;
      padding: 0;
      border: 0;
    }

    .card-body {
      padding: 0;
    }

    .card-icon {
      margin-right: 1.25rem;
    }

    [class^="svg"],
    [class^="svg"]::after {
      width: 2.875rem;
      height: 2.875rem;
    }

    [class^="svg"] {
      display: block;
    }

    [class^="svg"]::after {
      top: auto;
    }

    .card-title {
      margin-bottom: 1.25rem;
      font-size: 1.5rem;
    }
  }

  .modal-footer {
    align-self: flex-end;
    padding: 0;
    margin-top: 2.5rem;
    border: 0;

    .btn + .btn {
      margin-left: 1.25rem;
    }
  }
}

@include media-breakpoint-down(xs) {
  .modal {
    .modal-content {
      padding: 1.25rem;
    }

    .modal-header {
      margin: 0;
    }

    .modal-body {
      .card {
        flex-direction: column;
      }

      .card-icon {
        align-self: center;
        margin-right: 0;
      }

      .card-icon,
      [class^="svg"]::after {
        width: 4rem;
        height: 4rem;
      }

      .card-title {
        font-size: 1rem;
      }

      .card-body {
        margin-top: 1.25rem;
      }
    }

    .modal-footer {
      align-self: center;
    }
  }
}
