//
// Mega menu
//

.mega-menu {
  overflow: hidden;
}

.mega-menu > .navbar-nav {
  transition: transform .5s;
  transform: translateX(0);
}

.mega-menu-panel > .navbar-nav,
.mega-menu-panel > .container > .navbar-nav {
  flex-direction: column;
}

.mega-menu-panel > .navbar-nav .navbar-nav,
.mega-menu-panel > .container > .navbar-nav .navbar-nav {
  position: absolute;
  top: 0;
  left: 100%;
  display: none;
  width: 100%;
}
