@charset "UTF-8";
/*!
 * Boosted v4.1.1 (http://boosted.orange.com)
 * Copyright 2014-2018 The Boosted Authors
 * Copyright 2014-2018 Orange
 * Licensed under MIT (https://github.com/orange-opensource/orange-boosted-bootstrap/blob/master/LICENSE)
 * This a fork of Bootstrap : Initial license below
 * Bootstrap v4.1.1 (https://getbootstrap.com)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #527edb;
  --indigo: #6610f2;
  --purple: #a885d8;
  --pink: #ffb4e6;
  --red: #cd3c14;
  --orange: #f16e00;
  --yellow: #fc0;
  --green: #32c832;
  --teal: #50be87;
  --cyan: #4BB4E6;
  --white: #fff;
  --gray: #999;
  --gray-dark: #595959;
  --primary: #f16e00;
  --secondary: #000;
  --success: #32c832;
  --info: #527edb;
  --warning: #fc0;
  --danger: #cd3c14;
  --light: #ddd;
  --dark: #000;
  --breakpoint-xs: 0;
  --breakpoint-sm: 480px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 980px;
  --breakpoint-xl: 1220px;
  --breakpoint-xxl: 1380px;
  --font-family-sans-serif: "HelvNeueOrange", "Helvetica Neue", Helvetica, Arial, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@-ms-viewport {
  width: device-width;
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: "HelvNeueOrange", "Helvetica Neue", Helvetica, Arial, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  color: #000;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 1.25rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

dfn {
  font-style: italic;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #000;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

a:hover {
  color: #f16e00;
  text-decoration: none;
}

p a {
  text-decoration: underline;
}

p a:hover, p a:focus {
  text-decoration: underline;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.625rem 0.625rem 0.625rem 0;
  padding-bottom: 0.625rem 0.625rem 0.625rem 0;
  color: #666;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: .625rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 1.25rem;
  font-family: inherit;
  font-weight: 700;
  line-height: 1.1;
  color: inherit;
}

h1, .h1 {
  font-size: 2.125rem;
}

h2, .h2 {
  font-size: 1.875rem;
}

h3, .h3 {
  font-size: 1.5rem;
}

h4, .h4 {
  font-size: 1.25rem;
}

h5, .h5 {
  font-size: 1.125rem;
}

h6, .h6 {
  font-size: 1rem;
}

@media (max-width: 767.98px) {
  h1, .h1 {
    font-size: 1.25rem;
  }
  h2, .h2 {
    font-size: 1.125rem;
  }
  h3, .h3 {
    font-size: 1rem;
  }
  h4, .h4 {
    font-size: 0.875rem;
  }
  h5, .h5 {
    font-size: 0.875rem;
  }
  h6, .h6 {
    font-size: 0.875rem;
  }
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 3.75rem;
  font-weight: 700;
  line-height: 1.1;
}

.display-2 {
  font-size: 3.125rem;
  font-weight: 700;
  line-height: 1.1;
}

.display-3 {
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 1.1;
}

.display-4 {
  font-size: 2.125rem;
  font-weight: 700;
  line-height: 1.1;
}

hr {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  border: 0;
  border-top: 1px solid #eee;
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #f16e00;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.3125rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1.25rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #666;
}

.blockquote-footer::before {
  content: "\2014 \00A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.625rem;
  background-color: #fff;
  border: 2px solid #000;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.625rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #333;
}

code {
  font-size: 87.5%;
  color: #333;
  word-break: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.0625rem 0.0625rem;
  font-size: 87.5%;
  color: #000;
  background-color: #eee;
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #333;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container {
  margin-right: auto;
  margin-left: auto;
  padding-right: 5px;
  padding-left: 5px;
  margin-right: 1.25%;
  margin-left: 1.25%;
  max-width: 312px;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
}

@media (min-width: 480px) {
  .container {
    padding-right: 5px;
    padding-left: 5px;
  }
}

@media (min-width: 768px) {
  .container {
    padding-right: 10px;
    padding-left: 10px;
  }
}

@media (min-width: 980px) {
  .container {
    padding-right: 10px;
    padding-left: 10px;
  }
}

@media (min-width: 1220px) {
  .container {
    padding-right: 10px;
    padding-left: 10px;
  }
}

@media (min-width: 1380px) {
  .container {
    padding-right: 10px;
    padding-left: 10px;
  }
}

.container .container-fluid {
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 480px) {
  .container {
    margin-right: 1.25%;
    margin-left: 1.25%;
  }
  .container .container-fluid {
    margin-right: auto;
    margin-left: auto;
  }
}

@media (min-width: 768px) {
  .container {
    margin-right: 1.5625%;
    margin-left: 1.5625%;
  }
  .container .container-fluid {
    margin-right: auto;
    margin-left: auto;
  }
}

@media (min-width: 980px) {
  .container {
    margin-right: 3.125%;
    margin-left: 3.125%;
  }
  .container .container-fluid {
    margin-right: auto;
    margin-left: auto;
  }
}

@media (min-width: 1220px) {
  .container {
    margin-right: 3.125%;
    margin-left: 3.125%;
  }
  .container .container-fluid {
    margin-right: auto;
    margin-left: auto;
  }
}

@media (min-width: 1380px) {
  .container {
    margin-right: 4.16667%;
    margin-left: 4.16667%;
  }
  .container .container-fluid {
    margin-right: auto;
    margin-left: auto;
  }
}

@media (min-width: 480px) {
  .container {
    max-width: 468px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 744px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media (min-width: 980px) {
  .container {
    max-width: 960px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media (min-width: 1220px) {
  .container {
    max-width: 1200px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media (min-width: 1380px) {
  .container {
    max-width: 1320px;
    margin-right: auto;
    margin-left: auto;
  }
}

.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-right: 5px;
  padding-left: 5px;
  margin-right: 1.25%;
  margin-left: 1.25%;
  width: auto;
}

@media (min-width: 480px) {
  .container-fluid {
    padding-right: 5px;
    padding-left: 5px;
  }
}

@media (min-width: 768px) {
  .container-fluid {
    padding-right: 10px;
    padding-left: 10px;
  }
}

@media (min-width: 980px) {
  .container-fluid {
    padding-right: 10px;
    padding-left: 10px;
  }
}

@media (min-width: 1220px) {
  .container-fluid {
    padding-right: 10px;
    padding-left: 10px;
  }
}

@media (min-width: 1380px) {
  .container-fluid {
    padding-right: 10px;
    padding-left: 10px;
  }
}

.container-fluid .container-fluid {
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 480px) {
  .container-fluid {
    margin-right: 1.25%;
    margin-left: 1.25%;
  }
  .container-fluid .container-fluid {
    margin-right: auto;
    margin-left: auto;
  }
}

@media (min-width: 768px) {
  .container-fluid {
    margin-right: 1.5625%;
    margin-left: 1.5625%;
  }
  .container-fluid .container-fluid {
    margin-right: auto;
    margin-left: auto;
  }
}

@media (min-width: 980px) {
  .container-fluid {
    margin-right: 3.125%;
    margin-left: 3.125%;
  }
  .container-fluid .container-fluid {
    margin-right: auto;
    margin-left: auto;
  }
}

@media (min-width: 1220px) {
  .container-fluid {
    margin-right: 3.125%;
    margin-left: 3.125%;
  }
  .container-fluid .container-fluid {
    margin-right: auto;
    margin-left: auto;
  }
}

@media (min-width: 1380px) {
  .container-fluid {
    margin-right: 4.16667%;
    margin-left: 4.16667%;
  }
  .container-fluid .container-fluid {
    margin-right: auto;
    margin-left: auto;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

@media (min-width: 480px) {
  .row {
    margin-right: -5px;
    margin-left: -5px;
  }
}

@media (min-width: 768px) {
  .row {
    margin-right: -10px;
    margin-left: -10px;
  }
}

@media (min-width: 980px) {
  .row {
    margin-right: -10px;
    margin-left: -10px;
  }
}

@media (min-width: 1220px) {
  .row {
    margin-right: -10px;
    margin-left: -10px;
  }
}

@media (min-width: 1380px) {
  .row {
    margin-right: -10px;
    margin-left: -10px;
  }
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl,
.col-xxl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 5px;
  padding-left: 5px;
}

@media (min-width: 480px) {
  .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
  .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
  .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
  .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
  .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
  .col-xl-auto, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl,
  .col-xxl-auto {
    padding-right: 5px;
    padding-left: 5px;
  }
}

@media (min-width: 768px) {
  .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
  .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
  .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
  .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
  .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
  .col-xl-auto, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl,
  .col-xxl-auto {
    padding-right: 10px;
    padding-left: 10px;
  }
}

@media (min-width: 980px) {
  .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
  .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
  .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
  .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
  .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
  .col-xl-auto, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl,
  .col-xxl-auto {
    padding-right: 10px;
    padding-left: 10px;
  }
}

@media (min-width: 1220px) {
  .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
  .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
  .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
  .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
  .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
  .col-xl-auto, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl,
  .col-xxl-auto {
    padding-right: 10px;
    padding-left: 10px;
  }
}

@media (min-width: 1380px) {
  .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
  .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
  .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
  .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
  .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
  .col-xl-auto, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl,
  .col-xxl-auto {
    padding-right: 10px;
    padding-left: 10px;
  }
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.col-1 {
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.col-2 {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.col-5 {
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.col-8 {
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.col-11 {
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.333333%;
}

.offset-2 {
  margin-left: 16.666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.333333%;
}

.offset-5 {
  margin-left: 41.666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.333333%;
}

.offset-8 {
  margin-left: 66.666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.333333%;
}

.offset-11 {
  margin-left: 91.666667%;
}

@media (min-width: 480px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-sm-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.333333%;
  }
  .offset-sm-2 {
    margin-left: 16.666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.333333%;
  }
  .offset-sm-5 {
    margin-left: 41.666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.333333%;
  }
  .offset-sm-8 {
    margin-left: 66.666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.333333%;
  }
  .offset-sm-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-md-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-md-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-md-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-md-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-md-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.333333%;
  }
  .offset-md-2 {
    margin-left: 16.666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.333333%;
  }
  .offset-md-5 {
    margin-left: 41.666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.333333%;
  }
  .offset-md-8 {
    margin-left: 66.666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.333333%;
  }
  .offset-md-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 980px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-lg-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.333333%;
  }
  .offset-lg-2 {
    margin-left: 16.666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.333333%;
  }
  .offset-lg-5 {
    margin-left: 41.666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.333333%;
  }
  .offset-lg-8 {
    margin-left: 66.666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.333333%;
  }
  .offset-lg-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 1220px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-xl-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.333333%;
  }
  .offset-xl-2 {
    margin-left: 16.666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.333333%;
  }
  .offset-xl-5 {
    margin-left: 41.666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.333333%;
  }
  .offset-xl-8 {
    margin-left: 66.666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.333333%;
  }
  .offset-xl-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 1380px) {
  .col-xxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-xxl-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-xxl-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xxl-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xxl-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-xxl-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xxl-first {
    order: -1;
  }
  .order-xxl-last {
    order: 13;
  }
  .order-xxl-0 {
    order: 0;
  }
  .order-xxl-1 {
    order: 1;
  }
  .order-xxl-2 {
    order: 2;
  }
  .order-xxl-3 {
    order: 3;
  }
  .order-xxl-4 {
    order: 4;
  }
  .order-xxl-5 {
    order: 5;
  }
  .order-xxl-6 {
    order: 6;
  }
  .order-xxl-7 {
    order: 7;
  }
  .order-xxl-8 {
    order: 8;
  }
  .order-xxl-9 {
    order: 9;
  }
  .order-xxl-10 {
    order: 10;
  }
  .order-xxl-11 {
    order: 11;
  }
  .order-xxl-12 {
    order: 12;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.666667%;
  }
}

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1.25rem;
  background-color: #fff;
}

.table th,
.table td {
  padding: 0.625rem 0.625rem 0.625rem 0;
  vertical-align: top;
  border-top: 1px solid #ccc;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #ccc;
}

.table tbody + tbody {
  border-top: 2px solid #ccc;
}

.table .table {
  background-color: #fff;
}

.table-sm th,
.table-sm td {
  padding: 0.3125rem 0.3125rem 0.3125rem 0;
}

.table-bordered {
  border: 1px solid #ccc;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #ccc;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f6f6f6;
}

.table-hover tbody tr:hover {
  background-color: #eee;
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #f16e00;
}

.table-hover .table-primary:hover {
  background-color: #d86200;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #d86200;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: black;
  color: #fff;
}

.table-hover .table-secondary:hover {
  background-color: black;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: black;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #32c832;
}

.table-hover .table-success:hover {
  background-color: #2db42d;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #2db42d;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #527edb;
}

.table-hover .table-info:hover {
  background-color: #3d6ed7;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #3d6ed7;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffcc00;
}

.table-hover .table-warning:hover {
  background-color: #e6b800;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #e6b800;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #cd3c14;
  color: #fff;
}

.table-hover .table-danger:hover {
  background-color: #b63512;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #b63512;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #dddddd;
}

.table-hover .table-light:hover {
  background-color: #d0d0d0;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #d0d0d0;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: black;
  color: #fff;
}

.table-hover .table-dark:hover {
  background-color: black;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: black;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: #ddd;
}

.table-hover .table-active:hover {
  background-color: #d0d0d0;
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: #d0d0d0;
}

.table .thead-dark th {
  color: #fff;
  background-color: #000;
  border-color: #ccc;
}

.table .thead-light th {
  color: #000;
  background-color: #fff;
  border-color: #ccc;
}

.table-dark {
  color: #fff;
  background-color: #000;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #ccc;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: #333;
}

.table-dark.table-hover tbody tr:hover {
  background-color: #666;
}

@media (max-width: 479.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 979.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1219.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1379.98px) {
  .table-responsive-xxl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-xxl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.table-responsive > .table-bordered {
  border: 0;
}

label {
  font-weight: 700;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.5rem 0.625rem;
  font-size: 1rem;
  line-height: 1.25;
  color: #000;
  background-color: #fff;
  background-clip: padding-box;
  border: 2px solid #ccc;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:focus {
  color: #000;
  background-color: #fff;
  border-color: #999;
  outline: 0;
}

.form-control::placeholder {
  color: #666;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #eee;
  opacity: 1;
}

select.form-control:not([size]):not([multiple]) {
  height: calc(2.25rem + 4px);
}

select.form-control:focus::-ms-value {
  color: #000;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.5rem + 2px);
  padding-bottom: calc(0.5rem + 2px);
  margin-bottom: 0;
  font-size: inherit;
  font-weight: 700;
  line-height: 1.25;
}

.col-form-label-lg {
  padding-top: calc(0.875rem + 2px);
  padding-bottom: calc(0.875rem + 2px);
  font-size: 1.125rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.375rem + 2px);
  padding-bottom: calc(0.375rem + 2px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-bottom: 0;
  line-height: 1.25;
  color: #000;
  background-color: transparent;
  border: solid transparent;
  border-width: 2px 0;
}

.form-control-plaintext.form-control-sm, .input-group-sm > .form-control-plaintext.form-control,
.input-group-sm > .input-group-prepend > .form-control-plaintext.input-group-text,
.input-group-sm > .input-group-append > .form-control-plaintext.input-group-text,
.input-group-sm > .input-group-prepend > .form-control-plaintext.btn,
.input-group-sm > .input-group-append > .form-control-plaintext.btn, .form-control-plaintext.form-control-lg, .input-group-lg > .form-control-plaintext.form-control,
.input-group-lg > .input-group-prepend > .form-control-plaintext.input-group-text,
.input-group-lg > .input-group-append > .form-control-plaintext.input-group-text,
.input-group-lg > .input-group-prepend > .form-control-plaintext.btn,
.input-group-lg > .input-group-append > .form-control-plaintext.btn {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm, .input-group-sm > .form-control,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.375rem 0.625rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

select.form-control-sm:not([size]):not([multiple]), .input-group-sm > select.form-control:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.btn:not([size]):not([multiple]) {
  height: 2.0625rem;
}

.form-control-lg, .input-group-lg > .form-control,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.875rem 1.25rem;
  font-size: 1.125rem;
  line-height: 1.5;
}

select.form-control-lg:not([size]):not([multiple]), .input-group-lg > select.form-control:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.btn:not([size]):not([multiple]) {
  height: 3.4375rem;
}

.form-group {
  margin-bottom: 1.25rem;
}

.form-text {
  display: block;
  margin-top: 0.625rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
  margin-bottom: 0.625rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.25rem;
  margin-left: -1.25rem;
}

.form-check-input:disabled ~ .form-check-label {
  color: #666;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.625rem;
  font-size: 80%;
  color: #32c832;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(50, 200, 50, 0.8);
  border-radius: .2rem;
}

.was-validated .form-control:valid, .form-control.is-valid, .was-validated
.custom-select:valid,
.custom-select.is-valid {
  border-color: #32c832;
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated
.custom-select:valid:focus,
.custom-select.is-valid:focus {
  border-color: #32c832;
}

.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-tooltip, .was-validated
.custom-select:valid ~ .valid-feedback,
.was-validated
.custom-select:valid ~ .valid-tooltip,
.custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #32c832;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #32c832;
}

.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  background-color: #95e595;
}

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #58d558;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #32c832;
}

.was-validated .custom-file-input:valid ~ .custom-file-label::before, .custom-file-input.is-valid ~ .custom-file-label::before {
  border-color: inherit;
}

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.625rem;
  font-size: 80%;
  color: #cd3c14;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(205, 60, 20, 0.8);
  border-radius: .2rem;
}

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: #cd3c14;
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated
.custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  border-color: #cd3c14;
}

.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip, .was-validated
.custom-select:invalid ~ .invalid-feedback,
.was-validated
.custom-select:invalid ~ .invalid-tooltip,
.custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #cd3c14;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #cd3c14;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  background-color: #f18c70;
}

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: #ea532a;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #cd3c14;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label::before, .custom-file-input.is-invalid ~ .custom-file-label::before {
  border-color: inherit;
}

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 480px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 700;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 2px solid transparent;
  padding: 0.5rem 1.125rem;
  font-size: 1rem;
  line-height: 1.25;
  border-radius: 0;
  transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover, .btn:focus {
  text-decoration: none;
}

.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: none;
}

.btn.disabled, .btn:disabled {
  opacity: 1;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.btn:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active {
  background-image: none;
}

a.btn {
  text-decoration: none;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary,
.btn-warning {
  color: #fff;
  background-color: #f16e00;
  border-color: #f16e00;
}

.btn-primary:hover, .btn-primary:focus,
.btn-warning:hover,
.btn-warning:focus {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.btn-primary.disabled, .btn-primary:disabled,
.btn-warning.disabled,
.btn-warning:disabled {
  color: #fff;
  background-color: #ccc;
  border-color: #ccc;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle,
.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.show >
.btn-warning.dropdown-toggle {
  color: #000;
  background-color: #fff;
  border-color: #000;
}

.btn-primary.btn-inverse,
.btn-warning.btn-inverse {
  color: #fff;
  background-color: #f16e00;
  border-color: #f16e00;
}

.btn-primary.btn-inverse:hover, .btn-primary.btn-inverse:focus,
.btn-warning.btn-inverse:hover,
.btn-warning.btn-inverse:focus {
  color: #000;
  background-color: #fff;
  border-color: #fff;
}

.btn-primary.btn-inverse.disabled, .btn-primary.btn-inverse:disabled,
.btn-warning.btn-inverse.disabled,
.btn-warning.btn-inverse:disabled {
  color: #000;
  background-color: #666;
  border-color: #666;
}

.btn-primary.btn-inverse:not(:disabled):not(.disabled):active, .btn-primary.btn-inverse:not(:disabled):not(.disabled).active,
.show > .btn-primary.btn-inverse.dropdown-toggle,
.btn-warning.btn-inverse:not(:disabled):not(.disabled):active,
.btn-warning.btn-inverse:not(:disabled):not(.disabled).active,
.show >
.btn-warning.btn-inverse.dropdown-toggle {
  color: #fff;
  background-color: #000;
  border-color: #fff;
}

.btn-secondary,
.btn-light {
  color: #000;
  background-color: transparent;
  border-color: #000;
}

.btn-secondary:hover, .btn-secondary:focus,
.btn-light:hover,
.btn-light:focus {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.btn-secondary.disabled, .btn-secondary:disabled,
.btn-light.disabled,
.btn-light:disabled {
  color: #ccc;
  background-color: transparent;
  border-color: #ccc;
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle,
.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active,
.show >
.btn-light.dropdown-toggle {
  color: #fff;
  background-color: #f16e00;
  border-color: #f16e00;
}

.btn-secondary.btn-inverse,
.btn-light.btn-inverse {
  color: #000;
  background-color: #fff;
  border-color: #fff;
}

.btn-secondary.btn-inverse:hover, .btn-secondary.btn-inverse:focus,
.btn-light.btn-inverse:hover,
.btn-light.btn-inverse:focus {
  color: #fff;
  background-color: #000;
  border-color: #fff;
}

.btn-secondary.btn-inverse.disabled, .btn-secondary.btn-inverse:disabled,
.btn-light.btn-inverse.disabled,
.btn-light.btn-inverse:disabled {
  color: #000;
  background-color: #666;
  border-color: #666;
}

.btn-secondary.btn-inverse:not(:disabled):not(.disabled):active, .btn-secondary.btn-inverse:not(:disabled):not(.disabled).active,
.show > .btn-secondary.btn-inverse.dropdown-toggle,
.btn-light.btn-inverse:not(:disabled):not(.disabled):active,
.btn-light.btn-inverse:not(:disabled):not(.disabled).active,
.show >
.btn-light.btn-inverse.dropdown-toggle {
  color: #fff;
  background-color: #f16e00;
  border-color: #f16e00;
}

.btn-success {
  color: #000;
  background-color: #32c832;
  border-color: #32c832;
}

.btn-success:hover, .btn-success:focus {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #ccc;
  border-color: #ccc;
}

.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #f16e00;
  border-color: #f16e00;
}

.btn-success.btn-inverse {
  color: #000;
  background-color: #32c832;
  border-color: #32c832;
}

.btn-success.btn-inverse:hover, .btn-success.btn-inverse:focus {
  color: #000;
  background-color: #fff;
  border-color: #fff;
}

.btn-success.btn-inverse.disabled, .btn-success.btn-inverse:disabled {
  color: #000;
  background-color: #666;
  border-color: #666;
}

.btn-success.btn-inverse:not(:disabled):not(.disabled):active, .btn-success.btn-inverse:not(:disabled):not(.disabled).active,
.show > .btn-success.btn-inverse.dropdown-toggle {
  color: #fff;
  background-color: #f16e00;
  border-color: #f16e00;
}

.btn-info,
.btn-dark {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.btn-info:hover, .btn-info:focus,
.btn-dark:hover,
.btn-dark:focus {
  color: #000;
  background-color: #fff;
  border-color: #000;
}

.btn-info.disabled, .btn-info:disabled,
.btn-dark.disabled,
.btn-dark:disabled {
  color: #fff;
  background-color: #ccc;
  border-color: #ccc;
}

.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle,
.btn-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled).active,
.show >
.btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #f16e00;
  border-color: #f16e00;
}

.btn-info.btn-inverse,
.btn-dark.btn-inverse {
  color: #fff;
  background-color: transparent;
  border-color: #fff;
}

.btn-info.btn-inverse:hover, .btn-info.btn-inverse:focus,
.btn-dark.btn-inverse:hover,
.btn-dark.btn-inverse:focus {
  color: #000;
  background-color: #fff;
  border-color: #fff;
}

.btn-info.btn-inverse.disabled, .btn-info.btn-inverse:disabled,
.btn-dark.btn-inverse.disabled,
.btn-dark.btn-inverse:disabled {
  color: #666;
  background-color: transparent;
  border-color: #666;
}

.btn-info.btn-inverse:not(:disabled):not(.disabled):active, .btn-info.btn-inverse:not(:disabled):not(.disabled).active,
.show > .btn-info.btn-inverse.dropdown-toggle,
.btn-dark.btn-inverse:not(:disabled):not(.disabled):active,
.btn-dark.btn-inverse:not(:disabled):not(.disabled).active,
.show >
.btn-dark.btn-inverse.dropdown-toggle {
  color: #fff;
  background-color: #f16e00;
  border-color: #f16e00;
}

.btn-danger {
  color: #fff;
  background-color: #cd3c14;
  border-color: #cd3c14;
}

.btn-danger:hover, .btn-danger:focus {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #ccc;
  border-color: #ccc;
}

.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #f16e00;
  border-color: #f16e00;
}

.btn-danger.btn-inverse {
  color: #fff;
  background-color: #cd3c14;
  border-color: #cd3c14;
}

.btn-danger.btn-inverse:hover, .btn-danger.btn-inverse:focus {
  color: #000;
  background-color: #fff;
  border-color: #fff;
}

.btn-danger.btn-inverse.disabled, .btn-danger.btn-inverse:disabled {
  color: #000;
  background-color: #666;
  border-color: #666;
}

.btn-danger.btn-inverse:not(:disabled):not(.disabled):active, .btn-danger.btn-inverse:not(:disabled):not(.disabled).active,
.show > .btn-danger.btn-inverse.dropdown-toggle {
  color: #fff;
  background-color: #f16e00;
  border-color: #f16e00;
}

.btn-outline-primary {
  color: #f16e00;
  background-color: transparent;
  background-image: none;
  border-color: #f16e00;
}

.btn-outline-primary:hover, .btn-outline-primary:focus {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #f16e00;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #000;
  background-color: #f16e00;
  border-color: #f16e00;
}

.btn-outline-secondary {
  color: #000;
  background-color: transparent;
  background-image: none;
  border-color: #000;
}

.btn-outline-secondary:hover, .btn-outline-secondary:focus {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #000;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #000;
  background-color: #f16e00;
  border-color: #f16e00;
}

.btn-outline-success {
  color: #32c832;
  background-color: transparent;
  background-image: none;
  border-color: #32c832;
}

.btn-outline-success:hover, .btn-outline-success:focus {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #32c832;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #000;
  background-color: #f16e00;
  border-color: #f16e00;
}

.btn-outline-info {
  color: #000;
  background-color: transparent;
  background-image: none;
  border-color: #000;
}

.btn-outline-info:hover, .btn-outline-info:focus {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #000;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #000;
  background-color: #f16e00;
  border-color: #f16e00;
}

.btn-outline-warning {
  color: #f16e00;
  background-color: transparent;
  background-image: none;
  border-color: #f16e00;
}

.btn-outline-warning:hover, .btn-outline-warning:focus {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #f16e00;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #000;
  background-color: #f16e00;
  border-color: #f16e00;
}

.btn-outline-danger {
  color: #cd3c14;
  background-color: transparent;
  background-image: none;
  border-color: #cd3c14;
}

.btn-outline-danger:hover, .btn-outline-danger:focus {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #cd3c14;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #000;
  background-color: #f16e00;
  border-color: #f16e00;
}

.btn-outline-light {
  color: #ddd;
  background-color: transparent;
  background-image: none;
  border-color: #ddd;
}

.btn-outline-light:hover, .btn-outline-light:focus {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #ddd;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #000;
  background-color: #f16e00;
  border-color: #f16e00;
}

.btn-outline-dark {
  color: #000;
  background-color: transparent;
  background-image: none;
  border-color: #000;
}

.btn-outline-dark:hover, .btn-outline-dark:focus {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #000;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #000;
  background-color: #f16e00;
  border-color: #f16e00;
}

.btn-link {
  color: #000;
  background-color: transparent;
}

.btn-link.btn-inverse {
  color: #fff;
}

.btn-link:hover {
  color: #f16e00;
  text-decoration: none;
  background-color: transparent;
  border-color: transparent;
}

.btn-link:focus, .btn-link.focus {
  text-decoration: none;
  border-color: transparent;
  box-shadow: none;
}

.btn-link:disabled, .btn-link.disabled {
  color: #ccc;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.875rem 1.25rem;
  font-size: 1.125rem;
  line-height: 1.5;
  border-radius: 0;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.375rem 0.625rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}

@media screen and (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media screen and (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.371875em;
  vertical-align: 0.371875em;
  content: "";
  border-top: 0.4375em solid;
  border-right: 0.4375em solid transparent;
  border-bottom: 0;
  border-left: 0.4375em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0 0;
  margin: -2px 0 0;
  font-size: 1rem;
  color: #000;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 2px solid #000;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: -2px;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.371875em;
  vertical-align: 0.371875em;
  content: "";
  border-top: 0;
  border-right: 0.4375em solid transparent;
  border-bottom: 0.4375em solid;
  border-left: 0.4375em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: -2px;
}

.dropright .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.371875em;
  vertical-align: 0.371875em;
  content: "";
  border-top: 0.4375em solid transparent;
  border-right: 0;
  border-bottom: 0.4375em solid transparent;
  border-left: 0.4375em solid;
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: -2px;
}

.dropleft .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.371875em;
  vertical-align: 0.371875em;
  content: "";
}

.dropleft .dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-toggle::before {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 0.371875em;
  vertical-align: 0.371875em;
  content: "";
  border-top: 0.4375em solid transparent;
  border-right: 0.4375em solid;
  border-bottom: 0.4375em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.625rem 0;
  overflow: hidden;
  border-top: 1px solid #eee;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.625rem 0.625rem;
  clear: both;
  font-weight: 400;
  color: #000;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: #fff;
  text-decoration: none;
  background-color: #000;
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #000;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #666;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0 0.625rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #000;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.625rem 0.625rem;
  color: #000;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 0 1 auto;
}

.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}

.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-group .btn + .btn,
.btn-group .btn + .btn-group,
.btn-group .btn-group + .btn,
.btn-group .btn-group + .btn-group,
.btn-group-vertical .btn + .btn,
.btn-group-vertical .btn + .btn-group,
.btn-group-vertical .btn-group + .btn,
.btn-group-vertical .btn-group + .btn-group {
  margin-left: -2px;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:first-child {
  margin-left: 0;
}

.dropdown-toggle-split {
  padding-right: 0.84375rem;
  padding-left: 0.84375rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.46875rem;
  padding-left: 0.46875rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical .btn,
.btn-group-vertical .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn + .btn,
.btn-group-vertical > .btn + .btn-group,
.btn-group-vertical > .btn-group + .btn,
.btn-group-vertical > .btn-group + .btn-group {
  margin-top: -2px;
  margin-left: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}

.btn-group-toggle > .btn input[type="radio"],
.btn-group-toggle > .btn input[type="checkbox"],
.btn-group-toggle > .btn-group > .btn input[type="radio"],
.btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}

.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file:focus {
  z-index: 3;
}

.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -2px;
}

.input-group > .custom-file {
  display: flex;
  align-items: center;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}

.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}

.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -2px;
}

.input-group-prepend {
  margin-right: -2px;
}

.input-group-append {
  margin-left: -2px;
}

.input-group-prepend + .form-control {
  border-left-color: transparent;
}

.form-control:focus + .input-group-append,
.custom-select:focus + .input-group-append {
  position: relative;
  z-index: 5;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.625rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  color: #000;
  text-align: center;
  white-space: nowrap;
  background-color: #eee;
  border: 2px solid #ccc;
}

.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
  margin-top: 0;
}

.custom-control {
  position: relative;
  display: block;
  min-height: 1.25rem;
  padding-left: 1.875rem;
  margin-bottom: 0.625rem;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1.25rem;
}

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #f16e00;
  background-color: #f16e00;
}

.custom-control-input:checked ~ .custom-control-label::before::before {
  background-color: #f16e00;
}

.custom-control-input:focus ~ .custom-control-label::before {
  outline: dotted 1px #000;
  box-shadow: none;
}

.custom-control-input:active ~ .custom-control-label::before {
  color: #fff;
  background-color: #f16e00;
  border-color: #f16e00;
}

.custom-control-input:disabled ~ .custom-control-label {
  color: #767676;
}

.custom-control-input:disabled ~ .custom-control-label::before {
  border-color: #ccc;
}

.custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: #ccc;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
}

.custom-control-label::before {
  position: absolute;
  top: 0;
  left: -1.875rem;
  display: block;
  width: 1.25rem;
  height: 1.25rem;
  pointer-events: none;
  content: "";
  user-select: none;
  background-color: #fff;
  border-color: #666;
  border-style: solid;
  border-width: 2px;
}

.custom-control-label::after {
  position: absolute;
  top: 0rem;
  left: -1.875rem;
  display: block;
  width: 1.25rem;
  height: 1.25rem;
  content: "";
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 1rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8, %3Csvg%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M14%202l-8%208-3-3-2%202%205%205L16%204l-2-2z%22%20fill%3D%22%23FFF%22%2F%3E%3C%2Fsvg%3E");
}

.custom-checkbox .custom-control-input:checked:focus ~ .custom-control-label::before {
  outline-color: #000;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #f16e00;
  background-color: #f16e00;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath stroke='%23fff' d='M1 4h6'/%3E%3C/svg%3E");
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='2' fill='%23fff'/%3E%3C/svg%3E");
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 4px);
  padding: 0.4375rem 1.5rem 0.4375rem 0.5rem;
  font-weight: 700;
  line-height: 1.25;
  color: #000;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2014%207%22%3E%3Cpath%20d%3D%22M0%200h14L7%207%200%200z%22%2F%3E%3C%2Fsvg%3E") no-repeat right 0.5rem center;
  background-size: 8px 10px;
  border: 2px solid #ccc;
  border-radius: 0;
  appearance: none;
}

.custom-select:focus {
  border-color: #999;
  outline: 0;
  box-shadow: none;
}

.custom-select:focus::-ms-value {
  color: #000;
  background-color: #fff;
}

.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.5rem;
  background-image: none;
}

.custom-select:disabled {
  color: #999;
  background-color: #eee;
}

.custom-select::-ms-expand {
  opacity: 0;
}

.custom-select-sm {
  height: 2.0625rem;
  padding-top: 0.4375rem;
  padding-bottom: 0.4375rem;
  font-size: 75%;
}

.custom-select-lg {
  height: 3.4375rem;
  padding-top: 0.4375rem;
  padding-bottom: 0.4375rem;
  font-size: 125%;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 2.5rem;
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 2.5rem;
  margin: 0;
  opacity: 0;
}

.custom-file-input:focus ~ .custom-file-control {
  border-color: #999;
}

.custom-file-input:focus ~ .custom-file-control::before {
  border-color: #999;
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: #999;
  box-shadow: none;
}

.custom-file-input:focus ~ .custom-file-label::before {
  border-color: #999;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: 2.5rem;
  padding: 0.5rem 0.625rem;
  line-height: 1.25;
  color: #000;
  background-color: #fff;
  border: 2px solid #ccc;
}

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: 2.25rem;
  padding: 0.5rem 0.625rem;
  line-height: 1.25;
  color: #000;
  content: "Browse";
  background-color: #eee;
  border-left: 2px solid #ccc;
}

.custom-range {
  width: 100%;
  padding-left: 0;
  background-color: transparent;
  appearance: none;
}

.custom-range:focus {
  outline: none;
}

.custom-range::-moz-focus-outer {
  border: 0;
}

.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #000;
  border: 0;
  appearance: none;
}

.custom-range::-webkit-slider-thumb:focus {
  outline: none;
  box-shadow: 0 0 0 1px #fff, none;
}

.custom-range::-webkit-slider-thumb:active {
  background-color: #595959;
}

.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #eee;
  border-color: transparent;
}

.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #000;
  border: 0;
  appearance: none;
}

.custom-range::-moz-range-thumb:focus {
  outline: none;
  box-shadow: 0 0 0 1px #fff, none;
}

.custom-range::-moz-range-thumb:active {
  background-color: #595959;
}

.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #eee;
  border-color: transparent;
}

.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #000;
  border: 0;
  appearance: none;
}

.custom-range::-ms-thumb:focus {
  outline: none;
  box-shadow: 0 0 0 1px #fff, none;
}

.custom-range::-ms-thumb:active {
  background-color: #595959;
}

.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}

.custom-range::-ms-fill-lower {
  background-color: #eee;
}

.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #eee;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.625rem 1.25rem;
  font-weight: 700;
}

.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: #666;
}

.nav-tabs {
  border-bottom: 2px solid #000;
}

.nav-tabs .nav-item {
  margin-bottom: -2px;
}

.nav-tabs .nav-link {
  padding: 0.625rem calc(1.25rem - 2px);
  line-height: 1rem;
  border: 2px solid transparent;
  border-bottom-width: 0;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.nav-tabs .nav-link.disabled {
  color: #666;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #000;
  background-color: #fff;
  border-color: #000;
}

.nav-tabs .dropdown-menu {
  margin-top: -2px;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #000;
}

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0 0;
  font-weight: 700;
}

.navbar > .container,
.navbar > .container-fluid {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
  padding-right: 0;
  padding-left: 0;
}

.navbar-brand {
  display: inline-block;
  align-self: flex-end;
  padding-top: 0;
  padding-bottom: 0;
  margin: .625rem .3125rem;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: inherit;
  white-space: nowrap;
}

.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-brand + .navbar-brand {
  margin-right: 0;
  margin-left: 1.25rem;
}

.navbar-brand:focus {
  outline: 1px dotted #fff;
  outline-offset: .625rem;
}

.navbar-brand img {
  flex-wrap: wrap;
  width: 1.875rem;
  height: 1.875rem;
  margin-top: 0;
  margin-bottom: .25rem;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-top: auto;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-top: .875rem;
  padding-right: .625rem;
  padding-bottom: .875rem;
  padding-left: .625rem;
  margin-top: 0;
}

.navbar-nav .nav-link.active::before {
  display: none;
}

.navbar-nav .nav-link.icon {
  padding: .78125rem .625rem;
  margin-top: 0;
}

.navbar-nav .nav-link[class^="svg-"] {
  margin: .625rem;
}

.navbar-nav .nav-link[role="menuitem"]::before {
  float: right;
  margin-right: 1rem;
  font-family: icon-orange, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  color: #fff;
  content: "\e93e";
}

.navbar-nav .nav-link:focus {
  outline-offset: .625rem;
}

.navbar-nav .dropdown-menu {
  position: static;
  top: 2rem;
  float: none;
}

.navbar-nav .nav-item {
  margin-left: 0;
  font-size: 1.125rem;
  border-top: 1px solid #333;
}

.navbar-nav .dropdown {
  padding: 0;
}

.navbar-nav .dropdown .dropdown-toggle,
.navbar-nav .dropdown .dropdown-item {
  padding: .875rem 0 .875rem .625rem;
}

.navbar-nav .dropdown.show .dropdown-toggle::after {
  transform: rotateZ(180deg);
}

.navbar-nav .dropdown .dropdown-item {
  color: #fff;
  white-space: normal;
  background-color: #000;
  border-top: 1px solid #333;
}

.navbar-nav .dropdown .dropdown-item:focus {
  color: #f16e00;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-self: flex-end;
}

.navbar-collapse .navbar-nav {
  align-items: stretch;
}

.navbar-toggler {
  padding: 0.5rem 0.5rem;
  margin: 0.3125rem 0;
  font-size: 1rem;
  line-height: 1;
  background: transparent;
  border: 2px solid transparent;
}

.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
  background-color: #fff;
}

.navbar-toggler:hover .navbar-toggler-icon, .navbar-toggler:focus .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 1024 1024' width='20px' height='20px' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23000' d='M106.906 754.176c-10.957-2.15-21.299-10.65-26.419-21.402-2.765-6.042-3.174-7.782-3.174-15.974 0-8.397 0.307-9.83 3.379-16.077 4.301-8.704 10.138-14.541 18.637-18.637l6.656-3.174h812.032l6.656 3.174c8.397 4.096 14.848 10.445 18.637 18.432 9.114 19.456 1.024 41.574-18.637 50.995l-6.656 3.174-403.456 0.102c-221.901 0.102-405.402-0.205-407.654-0.614zM105.37 497.766c-10.65-2.867-19.251-10.035-24.678-20.582-2.97-5.53-3.277-7.066-3.277-15.872-0.102-8.806 0.205-10.342 3.072-16.384 3.994-8.397 10.342-14.746 18.842-18.842l6.656-3.174h812.032l6.656 3.277c7.475 3.686 9.216 5.018 14.438 11.571 5.53 6.861 7.578 13.312 7.578 23.552 0 7.578-0.41 9.523-3.174 15.36-3.994 8.499-10.342 14.848-18.842 18.842l-6.656 3.174-403.968 0.205c-317.85 0.102-404.992-0.102-408.678-1.126zM104.96 241.766c-11.366-3.584-19.354-10.547-24.474-21.094-2.765-5.939-3.174-7.68-3.174-15.872s0.41-9.933 3.174-15.872c3.994-8.397 10.342-14.746 18.842-18.842l6.656-3.174h812.032l6.656 3.174c8.499 3.994 14.848 10.342 18.842 18.842 2.867 5.939 3.174 7.68 3.174 15.872s-0.307 9.933-3.174 15.872c-3.994 8.397-10.342 14.746-18.842 18.842l-6.656 3.174-404.48 0.205c-324.915 0.102-405.299-0.102-408.576-1.126z'/%3E%3C/svg%3E");
}

.navbar-toggler:active {
  background-color: #f16e00;
}

.navbar-toggler:active .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 1024 1024' width='20px' height='20px' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23fff' d='M106.906 754.176c-10.957-2.15-21.299-10.65-26.419-21.402-2.765-6.042-3.174-7.782-3.174-15.974 0-8.397 0.307-9.83 3.379-16.077 4.301-8.704 10.138-14.541 18.637-18.637l6.656-3.174h812.032l6.656 3.174c8.397 4.096 14.848 10.445 18.637 18.432 9.114 19.456 1.024 41.574-18.637 50.995l-6.656 3.174-403.456 0.102c-221.901 0.102-405.402-0.205-407.654-0.614zM105.37 497.766c-10.65-2.867-19.251-10.035-24.678-20.582-2.97-5.53-3.277-7.066-3.277-15.872-0.102-8.806 0.205-10.342 3.072-16.384 3.994-8.397 10.342-14.746 18.842-18.842l6.656-3.174h812.032l6.656 3.277c7.475 3.686 9.216 5.018 14.438 11.571 5.53 6.861 7.578 13.312 7.578 23.552 0 7.578-0.41 9.523-3.174 15.36-3.994 8.499-10.342 14.848-18.842 18.842l-6.656 3.174-403.968 0.205c-317.85 0.102-404.992-0.102-408.678-1.126zM104.96 241.766c-11.366-3.584-19.354-10.547-24.474-21.094-2.765-5.939-3.174-7.68-3.174-15.872s0.41-9.933 3.174-15.872c3.994-8.397 10.342-14.746 18.842-18.842l6.656-3.174h812.032l6.656 3.174c8.499 3.994 14.848 10.342 18.842 18.842 2.867 5.939 3.174 7.68 3.174 15.872s-0.307 9.933-3.174 15.872c-3.994 8.397-10.342 14.746-18.842 18.842l-6.656 3.174-404.48 0.205c-324.915 0.102-405.299-0.102-408.576-1.126z'/%3E%3C/svg%3E");
}

.navbar-toggler:focus {
  outline: 0;
}

.navbar-toggler:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.25rem;
  height: 1.25rem;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (min-width: 480px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
    padding: 1.5625rem 0 1.3125rem;
  }
  .navbar-expand-sm .navbar-brand {
    margin: 0 .625rem 0 0;
  }
  .navbar-expand-sm .navbar-brand img {
    width: 3.125rem;
    height: 3.125rem;
  }
  .navbar-expand-sm.minimized {
    padding-top: 1.25rem;
    padding-bottom: 1rem;
  }
  .navbar-expand-sm.minimized .navbar-brand img {
    width: 2.5rem;
    height: 2.5rem;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto;
  }
  .navbar-expand-sm .navbar-nav .dropdown-item {
    color: #000;
    background-color: #fff;
    border: 0;
  }
  .navbar-expand-sm .navbar-nav .dropdown-item.active, .navbar-expand-sm .navbar-nav .dropdown-item:hover {
    color: #fff;
    background-color: #000;
  }
  .navbar-expand-sm .navbar-nav .nav-item {
    font-size: 1rem;
    border: 0;
  }
  .navbar-expand-sm .navbar-nav .nav-item.dropdown {
    align-self: flex-end;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 0;
    margin-left: 1.25rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    flex-wrap: nowrap;
    padding-right: 10px;
    padding-left: 10px;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
    padding: 1.5625rem 0 1.3125rem;
  }
  .navbar-expand-md .navbar-brand {
    margin: 0 .625rem 0 0;
  }
  .navbar-expand-md .navbar-brand img {
    width: 3.125rem;
    height: 3.125rem;
  }
  .navbar-expand-md.minimized {
    padding-top: 1.25rem;
    padding-bottom: 1rem;
  }
  .navbar-expand-md.minimized .navbar-brand img {
    width: 2.5rem;
    height: 2.5rem;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto;
  }
  .navbar-expand-md .navbar-nav .dropdown-item {
    color: #000;
    background-color: #fff;
    border: 0;
  }
  .navbar-expand-md .navbar-nav .dropdown-item.active, .navbar-expand-md .navbar-nav .dropdown-item:hover {
    color: #fff;
    background-color: #000;
  }
  .navbar-expand-md .navbar-nav .nav-item {
    font-size: 1rem;
    border: 0;
  }
  .navbar-expand-md .navbar-nav .nav-item.dropdown {
    align-self: flex-end;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 0;
    margin-left: 1.25rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    flex-wrap: nowrap;
    padding-right: 10px;
    padding-left: 10px;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
  }
}

@media (min-width: 980px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
    padding: 1.5625rem 0 1.3125rem;
  }
  .navbar-expand-lg .navbar-brand {
    margin: 0 .625rem 0 0;
  }
  .navbar-expand-lg .navbar-brand img {
    width: 3.125rem;
    height: 3.125rem;
  }
  .navbar-expand-lg.minimized {
    padding-top: 1.25rem;
    padding-bottom: 1rem;
  }
  .navbar-expand-lg.minimized .navbar-brand img {
    width: 2.5rem;
    height: 2.5rem;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto;
  }
  .navbar-expand-lg .navbar-nav .dropdown-item {
    color: #000;
    background-color: #fff;
    border: 0;
  }
  .navbar-expand-lg .navbar-nav .dropdown-item.active, .navbar-expand-lg .navbar-nav .dropdown-item:hover {
    color: #fff;
    background-color: #000;
  }
  .navbar-expand-lg .navbar-nav .nav-item {
    font-size: 1rem;
    border: 0;
  }
  .navbar-expand-lg .navbar-nav .nav-item.dropdown {
    align-self: flex-end;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 0;
    margin-left: 1.25rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    flex-wrap: nowrap;
    padding-right: 10px;
    padding-left: 10px;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
  }
}

@media (min-width: 1220px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
    padding: 1.5625rem 0 1.3125rem;
  }
  .navbar-expand-xl .navbar-brand {
    margin: 0 .625rem 0 0;
  }
  .navbar-expand-xl .navbar-brand img {
    width: 3.125rem;
    height: 3.125rem;
  }
  .navbar-expand-xl.minimized {
    padding-top: 1.25rem;
    padding-bottom: 1rem;
  }
  .navbar-expand-xl.minimized .navbar-brand img {
    width: 2.5rem;
    height: 2.5rem;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto;
  }
  .navbar-expand-xl .navbar-nav .dropdown-item {
    color: #000;
    background-color: #fff;
    border: 0;
  }
  .navbar-expand-xl .navbar-nav .dropdown-item.active, .navbar-expand-xl .navbar-nav .dropdown-item:hover {
    color: #fff;
    background-color: #000;
  }
  .navbar-expand-xl .navbar-nav .nav-item {
    font-size: 1rem;
    border: 0;
  }
  .navbar-expand-xl .navbar-nav .nav-item.dropdown {
    align-self: flex-end;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 0;
    margin-left: 1.25rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    flex-wrap: nowrap;
    padding-right: 10px;
    padding-left: 10px;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
  }
}

@media (min-width: 1380px) {
  .navbar-expand-xxl {
    flex-flow: row nowrap;
    justify-content: flex-start;
    padding: 1.5625rem 0 1.3125rem;
  }
  .navbar-expand-xxl .navbar-brand {
    margin: 0 .625rem 0 0;
  }
  .navbar-expand-xxl .navbar-brand img {
    width: 3.125rem;
    height: 3.125rem;
  }
  .navbar-expand-xxl.minimized {
    padding-top: 1.25rem;
    padding-bottom: 1rem;
  }
  .navbar-expand-xxl.minimized .navbar-brand img {
    width: 2.5rem;
    height: 2.5rem;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-item {
    color: #000;
    background-color: #fff;
    border: 0;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-item.active, .navbar-expand-xxl .navbar-nav .dropdown-item:hover {
    color: #fff;
    background-color: #000;
  }
  .navbar-expand-xxl .navbar-nav .nav-item {
    font-size: 1rem;
    border: 0;
  }
  .navbar-expand-xxl .navbar-nav .nav-item.dropdown {
    align-self: flex-end;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 0;
    margin-left: 1.25rem;
  }
  .navbar-expand-xxl > .container,
  .navbar-expand-xxl > .container-fluid {
    flex-wrap: nowrap;
    padding-right: 10px;
    padding-left: 10px;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
  }
}

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
  padding: 1.5625rem 0 1.3125rem;
}

.navbar-expand .navbar-brand {
  margin: 0 .625rem 0 0;
}

.navbar-expand .navbar-brand img {
  width: 3.125rem;
  height: 3.125rem;
}

.navbar-expand.minimized {
  padding-top: 1.25rem;
  padding-bottom: 1rem;
}

.navbar-expand.minimized .navbar-brand img {
  width: 2.5rem;
  height: 2.5rem;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .dropdown-menu-right {
  right: 0;
  left: auto;
}

.navbar-expand .navbar-nav .dropdown-item {
  color: #000;
  background-color: #fff;
  border: 0;
}

.navbar-expand .navbar-nav .dropdown-item.active, .navbar-expand .navbar-nav .dropdown-item:hover {
  color: #fff;
  background-color: #000;
}

.navbar-expand .navbar-nav .nav-item {
  font-size: 1rem;
  border: 0;
}

.navbar-expand .navbar-nav .nav-item.dropdown {
  align-self: flex-end;
}

.navbar-expand .navbar-nav .nav-link {
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  margin-left: 1.25rem;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid {
  flex-wrap: nowrap;
  padding-right: 10px;
  padding-left: 10px;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-expand .dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
}

.navbar-light .navbar-brand {
  color: #000;
}

.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: #f16e00;
}

.navbar-light .navbar-nav .nav-link {
  color: #000;
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: #f16e00;
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: #ddd;
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: #f16e00;
}

.navbar-light .navbar-toggler {
  color: #000;
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 1024 1024' width='20px' height='20px' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23fff' d='M106.906 754.176c-10.957-2.15-21.299-10.65-26.419-21.402-2.765-6.042-3.174-7.782-3.174-15.974 0-8.397 0.307-9.83 3.379-16.077 4.301-8.704 10.138-14.541 18.637-18.637l6.656-3.174h812.032l6.656 3.174c8.397 4.096 14.848 10.445 18.637 18.432 9.114 19.456 1.024 41.574-18.637 50.995l-6.656 3.174-403.456 0.102c-221.901 0.102-405.402-0.205-407.654-0.614zM105.37 497.766c-10.65-2.867-19.251-10.035-24.678-20.582-2.97-5.53-3.277-7.066-3.277-15.872-0.102-8.806 0.205-10.342 3.072-16.384 3.994-8.397 10.342-14.746 18.842-18.842l6.656-3.174h812.032l6.656 3.277c7.475 3.686 9.216 5.018 14.438 11.571 5.53 6.861 7.578 13.312 7.578 23.552 0 7.578-0.41 9.523-3.174 15.36-3.994 8.499-10.342 14.848-18.842 18.842l-6.656 3.174-403.968 0.205c-317.85 0.102-404.992-0.102-408.678-1.126zM104.96 241.766c-11.366-3.584-19.354-10.547-24.474-21.094-2.765-5.939-3.174-7.68-3.174-15.872s0.41-9.933 3.174-15.872c3.994-8.397 10.342-14.746 18.842-18.842l6.656-3.174h812.032l6.656 3.174c8.499 3.994 14.848 10.342 18.842 18.842 2.867 5.939 3.174 7.68 3.174 15.872s-0.307 9.933-3.174 15.872c-3.994 8.397-10.342 14.746-18.842 18.842l-6.656 3.174-404.48 0.205c-324.915 0.102-405.299-0.102-408.576-1.126z'/%3E%3C/svg%3E");
}

.navbar-light .navbar-text {
  color: #000;
}

.navbar-light .navbar-text a {
  color: #f16e00;
}

.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: #f16e00;
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #f16e00;
}

.navbar-dark .navbar-nav .nav-link {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: #f16e00;
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: #ddd;
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #f16e00;
}

.navbar-dark .navbar-toggler {
  color: #fff;
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 1024 1024' width='20px' height='20px' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23fff' d='M106.906 754.176c-10.957-2.15-21.299-10.65-26.419-21.402-2.765-6.042-3.174-7.782-3.174-15.974 0-8.397 0.307-9.83 3.379-16.077 4.301-8.704 10.138-14.541 18.637-18.637l6.656-3.174h812.032l6.656 3.174c8.397 4.096 14.848 10.445 18.637 18.432 9.114 19.456 1.024 41.574-18.637 50.995l-6.656 3.174-403.456 0.102c-221.901 0.102-405.402-0.205-407.654-0.614zM105.37 497.766c-10.65-2.867-19.251-10.035-24.678-20.582-2.97-5.53-3.277-7.066-3.277-15.872-0.102-8.806 0.205-10.342 3.072-16.384 3.994-8.397 10.342-14.746 18.842-18.842l6.656-3.174h812.032l6.656 3.277c7.475 3.686 9.216 5.018 14.438 11.571 5.53 6.861 7.578 13.312 7.578 23.552 0 7.578-0.41 9.523-3.174 15.36-3.994 8.499-10.342 14.848-18.842 18.842l-6.656 3.174-403.968 0.205c-317.85 0.102-404.992-0.102-408.678-1.126zM104.96 241.766c-11.366-3.584-19.354-10.547-24.474-21.094-2.765-5.939-3.174-7.68-3.174-15.872s0.41-9.933 3.174-15.872c3.994-8.397 10.342-14.746 18.842-18.842l6.656-3.174h812.032l6.656 3.174c8.499 3.994 14.848 10.342 18.842 18.842 2.867 5.939 3.174 7.68 3.174 15.872s-0.307 9.933-3.174 15.872c-3.994 8.397-10.342 14.746-18.842 18.842l-6.656 3.174-404.48 0.205c-324.915 0.102-405.299-0.102-408.576-1.126z'/%3E%3C/svg%3E");
}

.navbar-dark .navbar-text {
  color: #fff;
}

.navbar-dark .navbar-text a {
  color: #f16e00;
}

.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #f16e00;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 2px solid #000;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  font-weight: 700;
  color: #fff;
  background-color: #000;
  border-bottom: 2px solid #000;
}

.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border-top: 2px solid #000;
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img {
  width: 100%;
}

.card-img-top {
  width: 100%;
}

.card-img-bottom {
  width: 100%;
}

.card-deck {
  display: flex;
  flex-direction: column;
}

.card-deck .card {
  margin-bottom: 2.5rem;
}

@media (min-width: 480px) {
  .card-deck {
    flex-flow: row wrap;
    margin-right: -2.5rem;
    margin-left: -2.5rem;
  }
  .card-deck .card {
    display: flex;
    flex: 1 0 0%;
    flex-direction: column;
    margin-right: 2.5rem;
    margin-bottom: 0;
    margin-left: 2.5rem;
  }
}

.card-group {
  display: flex;
  flex-direction: column;
}

.card-group > .card {
  margin-bottom: 10px;
}

@media (min-width: 480px) {
  .card-group {
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
}

.card-columns .card {
  margin-bottom: 2.5rem;
}

@media (min-width: 480px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  /* boosted mod */
}

.accordion .card {
  margin-bottom: 0;
  border: 0;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

.accordion .card ~ .card {
  border-top: 0;
}

.accordion .card-header {
  padding: 0;
  background-color: #fff;
  border: 0;
}

.accordion .card-header,
.accordion .card-header h5 {
  margin: 0;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1;
}

.accordion .card-header button,
.accordion .card-header a {
  position: relative;
  display: block;
  width: 100%;
  padding: 1rem 2.125rem 1rem 0;
  line-height: 1.125rem;
  text-align: left;
  border: 0;
}

.accordion .card-header button::after,
.accordion .card-header a::after {
  position: absolute;
  top: 1.3125rem;
  right: .625rem;
  width: .875rem;
  height: .4375rem;
  content: "";
  background-image: url("data:image/svg+xml;charset=utf8, %3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2014%207%22%3E%3Cpath%20d%3D%22M7%207L0%200h14L7%207z%22%2F%3E%3C%2Fsvg%3E");
}

.accordion .card-header button[aria-expanded="true"]::after,
.accordion .card-header a[aria-expanded="true"]::after {
  transform: rotate(180deg);
}

.accordion .card-body {
  padding: 0 1.5rem 0 0;
  margin: 0 0 1.25rem 0;
}

@media (max-width: 767.98px) {
  .accordion .card {
    border-top-width: 2px;
    border-bottom-width: 2px;
  }
  .accordion .card ~ .card {
    border-top: 0;
  }
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.625rem 0;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.428571;
  list-style: none;
  background-color: #fff;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.625rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.625rem;
  font-family: icon-orange;
  font-size: 0.625rem;
  line-height: 1;
  color: #000;
  content: "";
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #f16e00;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  min-width: 2.5rem;
  min-height: 2.5rem;
  padding: 0.625rem 0.8rem;
  margin-left: 0;
  font-weight: 700;
  line-height: 1;
  color: #000;
  background-color: #fff;
  border: 2px solid transparent;
}

.page-link:hover {
  z-index: 2;
  color: #000;
  text-decoration: none;
  background-color: #fff;
  border-color: #000;
}

.page-link:hover span {
  color: #000;
}

.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: none;
}

.page-link span {
  color: #fff;
}

.page-link:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.page-item.presentation {
  padding: .625rem 0;
  font-weight: 700;
}

.page-item.presentation + .page-item .page-link, .page-item:first-child .page-link {
  margin-left: 0;
  border-color: #000;
}

.page-item.presentation + .page-item .page-link::before, .page-item:first-child .page-link::before {
  position: absolute;
  top: .6875rem;
  left: .8125rem;
  width: .5625rem;
  height: .875rem;
  content: "";
  background-image: url("data:image/svg+xml;charset=utf8, %3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%209%2014%22%3E%3Cpath%20d%3D%22M9%202L7%200%200%207l7%207%202-2-5-5%205-5z%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
}

.page-item.presentation + .page-item .page-link:hover::before, .page-item.presentation + .page-item .page-link:focus::before, .page-item:first-child .page-link:hover::before, .page-item:first-child .page-link:focus::before {
  background-image: url("data:image/svg+xml;charset=utf8, %3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%209%2014%22%3E%3Cpath%20d%3D%22M9%202L7%200%200%207l7%207%202-2-5-5%205-5z%22%20fill%3D%22%23fff%22%2F%3E%3C%2Fsvg%3E");
}

.page-item.presentation + .page-item .page-link.has-label, .page-item:first-child .page-link.has-label {
  padding-right: 1.125rem;
  padding-left: 2rem;
}

.page-item.presentation + .page-item .page-link:hover, .page-item.presentation + .page-item .page-link:focus, .page-item:first-child .page-link:hover, .page-item:first-child .page-link:focus, .page-item:last-child .page-link:hover, .page-item:last-child .page-link:focus {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.page-item:last-child .page-link {
  border-color: #000;
}

.page-item:last-child .page-link::before {
  position: absolute;
  top: .6875rem;
  right: .8125rem;
  width: .5625rem;
  height: .875rem;
  content: "";
  background-image: url("data:image/svg+xml;charset=utf8, %3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%209%2014%22%3E%3Cpath%20d%3D%22M0%202l2-2%207%207-7%207-2-2%205-5-5-5z%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
}

.page-item:last-child .page-link:hover::before, .page-item:last-child .page-link:focus::before {
  background-image: url("data:image/svg+xml;charset=utf8, %3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%209%2014%22%3E%3Cpath%20d%3D%22M0%202l2-2%207%207-7%207-2-2%205-5-5-5z%22%20fill%3D%22%23fff%22%2F%3E%3C%2Fsvg%3E");
}

.page-item:last-child .page-link.has-label {
  padding-right: 2rem;
  padding-left: 1.125rem;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.page-item.active:first-child .page-link::before {
  background-image: url("data:image/svg+xml;charset=utf8, %3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%209%2014%22%3E%3Cpath%20d%3D%22M9%202L7%200%200%207l7%207%202-2-5-5%205-5z%22%20fill%3D%22%23fff%22%2F%3E%3C%2Fsvg%3E");
}

.page-item.active:last-child .page-link::before {
  background-image: url("data:image/svg+xml;charset=utf8, %3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%209%2014%22%3E%3Cpath%20d%3D%22M0%202l2-2%207%207-7%207-2-2%205-5-5-5z%22%20fill%3D%22%23fff%22%2F%3E%3C%2Fsvg%3E");
}

.page-item.disabled .page-link {
  color: #ccc;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #ccc;
}

.page-item.disabled:first-child .page-link::before {
  background-image: url("data:image/svg+xml;charset=utf8, %3Csvg%20viewBox%3D%220%200%209%2014%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M9%202L7%200%200%207l7%207%202-2-5-5%205-5z%22%20fill%3D%22%23DDD%22%2F%3E%3C%2Fsvg%3E");
}

.page-item.disabled:last-child .page-link::before {
  background-image: url("data:image/svg+xml;charset=utf8, %3Csvg%20viewBox%3D%220%200%209%2014%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M0%202l2-2%207%207-7%207-2-2%205-5-5-5z%22%20fill%3D%22%23DDD%22%2F%3E%3C%2Fsvg%3E");
}

.page-item + .page-item {
  margin-left: .625rem;
}

.pagination-lg .page-link {
  min-width: 3.125rem;
  min-height: 3.125rem;
  padding: 0.875rem 1.125rem;
  font-size: 1.125rem;
  line-height: 1.111111;
}

.pagination-lg .page-item:first-child .page-link::before,
.pagination-lg .page-item:last-child .page-link::before {
  top: 1rem;
}

.pagination-sm .page-link {
  min-width: 1.875rem;
  min-height: 1.875rem;
  padding: 0.375rem 0.625rem;
  font-size: 0.875rem;
  line-height: 1.428571;
}

.pagination-sm .page-item:first-child .page-link::before,
.pagination-sm .page-item:last-child .page-link::before {
  top: auto;
}

.badge {
  display: inline-block;
  padding: 0.1875rem 0.625rem;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.625rem;
  padding-left: 0.625rem;
}

.badge-primary {
  color: #fff;
  background-color: #f16e00;
}

.badge-primary[href]:hover, .badge-primary[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #be5700;
}

.badge-secondary {
  color: #fff;
  background-color: #000;
}

.badge-secondary[href]:hover, .badge-secondary[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: black;
}

.badge-success {
  color: #000;
  background-color: #32c832;
}

.badge-success[href]:hover, .badge-success[href]:focus {
  color: #000;
  text-decoration: none;
  background-color: #289f28;
}

.badge-info {
  color: #000;
  background-color: #527edb;
}

.badge-info[href]:hover, .badge-info[href]:focus {
  color: #000;
  text-decoration: none;
  background-color: #2b60cf;
}

.badge-warning {
  color: #000;
  background-color: #fc0;
}

.badge-warning[href]:hover, .badge-warning[href]:focus {
  color: #000;
  text-decoration: none;
  background-color: #cca300;
}

.badge-danger {
  color: #fff;
  background-color: #cd3c14;
}

.badge-danger[href]:hover, .badge-danger[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #9f2e0f;
}

.badge-light {
  color: #000;
  background-color: #ddd;
}

.badge-light[href]:hover, .badge-light[href]:focus {
  color: #000;
  text-decoration: none;
  background-color: #c4c4c4;
}

.badge-dark {
  color: #fff;
  background-color: #000;
}

.badge-dark[href]:hover, .badge-dark[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: black;
}

.badge[href]:focus {
  outline-color: #fff;
  outline-offset: -1px;
}

.badge[href]:focus.badge-light {
  outline-color: #000;
}

.jumbotron {
  padding: 2.5rem 1.25rem;
  margin-bottom: 2.5rem;
  background-color: #fff;
}

@media (min-width: 480px) {
  .jumbotron {
    padding: 5rem 2.5rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
}

.alert {
  position: relative;
  padding: 1.25rem 1.25rem;
  margin-bottom: 1.25rem;
  border: 0 solid transparent;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 1.25rem 1.25rem;
  line-height: 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #000;
  background-color: #f16e00;
  border-color: #f16e00;
}

.alert-primary hr {
  border-top-color: #d86200;
}

.alert-primary .alert-link {
  color: black;
}

.alert-secondary {
  color: #fff;
  background-color: black;
  border-color: black;
}

.alert-secondary hr {
  border-top-color: black;
}

.alert-secondary .alert-link {
  color: #e6e6e6;
}

.alert-success {
  color: #000;
  background-color: #32c832;
  border-color: #32c832;
}

.alert-success hr {
  border-top-color: #2db42d;
}

.alert-success .alert-link {
  color: black;
}

.alert-info {
  color: #000;
  background-color: #527edb;
  border-color: #527edb;
}

.alert-info hr {
  border-top-color: #3d6ed7;
}

.alert-info .alert-link {
  color: black;
}

.alert-warning {
  color: #000;
  background-color: #ffcc00;
  border-color: #ffcc00;
}

.alert-warning hr {
  border-top-color: #e6b800;
}

.alert-warning .alert-link {
  color: black;
}

.alert-danger {
  color: #fff;
  background-color: #cd3c14;
  border-color: #cd3c14;
}

.alert-danger hr {
  border-top-color: #b63512;
}

.alert-danger .alert-link {
  color: #e6e6e6;
}

.alert-light {
  color: #000;
  background-color: #dddddd;
  border-color: #dddddd;
}

.alert-light hr {
  border-top-color: #d0d0d0;
}

.alert-light .alert-link {
  color: black;
}

.alert-dark {
  color: #fff;
  background-color: black;
  border-color: black;
}

.alert-dark hr {
  border-top-color: black;
}

.alert-dark .alert-link {
  color: #e6e6e6;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1.25rem 0;
  }
  to {
    background-position: 0 0;
  }
}

.progress {
  display: flex;
  height: 1.25rem;
  overflow: hidden;
  font-size: 1rem;
  background-color: #ccc;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #000;
  text-align: center;
  white-space: nowrap;
  background-color: #f16e00;
  transition: width 0.6s ease;
}

@media screen and (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

@media (max-width: 979.98px) {
  .progress {
    width: 100%;
    height: 0.625rem;
    line-height: 0.625rem;
  }
  .progress-bar {
    font-size: 0.875rem;
    line-height: 0.625rem;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1.25rem 1.25rem;
}

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.list-group-item-action {
  width: 100%;
  color: #000;
  text-align: inherit;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  color: #f16e00;
  text-decoration: none;
  background-color: #f6f6f6;
}

.list-group-item-action:active {
  color: #000;
  background-color: #eee;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.625rem 0.625rem;
  margin-bottom: -2px;
  background-color: #fff;
  border: 2px solid #000;
}

.list-group-item:last-child {
  margin-bottom: 0;
}

.list-group-item:hover, .list-group-item:focus {
  z-index: 1;
  text-decoration: none;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: #ccc;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
}

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0;
}

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0;
}

.list-group-item-primary {
  color: #000;
  background-color: #f16e00;
}

.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #000;
  background-color: #d86200;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.list-group-item-secondary {
  color: #fff;
  background-color: black;
}

.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #fff;
  background-color: black;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.list-group-item-success {
  color: #000;
  background-color: #32c832;
}

.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #000;
  background-color: #2db42d;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.list-group-item-info {
  color: #000;
  background-color: #527edb;
}

.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #000;
  background-color: #3d6ed7;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.list-group-item-warning {
  color: #000;
  background-color: #ffcc00;
}

.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #000;
  background-color: #e6b800;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.list-group-item-danger {
  color: #000;
  background-color: #cd3c14;
}

.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #000;
  background-color: #b63512;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.list-group-item-light {
  color: #000;
  background-color: #dddddd;
}

.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #000;
  background-color: #d0d0d0;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.list-group-item-dark {
  color: #fff;
  background-color: black;
}

.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #fff;
  background-color: black;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
}

.close:hover, .close:focus {
  color: #000;
  text-decoration: none;
  opacity: .75;
}

.close:not(:disabled):not(.disabled) {
  cursor: pointer;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
}

.modal-open {
  overflow: hidden;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.625rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -25%);
}

@media screen and (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: translate(0, 0);
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - (0.625rem * 2));
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 2px solid #000;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #333;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0.625rem;
  border-bottom: 2px solid #000;
}

.modal-header .close {
  padding: 0.625rem;
  margin: -0.625rem -0.625rem -0.625rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.25;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 0.625rem;
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0.625rem;
  border-top: 2px solid #000;
}

.modal-footer > :not(:first-child) {
  margin-left: .25rem;
}

.modal-footer > :not(:last-child) {
  margin-right: .25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 480px) {
  .modal-dialog {
    max-width: 700px;
    margin: 1.875rem auto;
  }
  .modal-dialog-centered {
    min-height: calc(100% - (1.875rem * 2));
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 980px) {
  .modal-lg {
    max-width: 700px;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0.3125rem;
  font-family: "HelvNeueOrange", "Helvetica Neue", Helvetica, Arial, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.25;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  font-weight: 700;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.625rem;
  height: 0.625rem;
}

.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.625rem 0;
}

.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0;
}

.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
  top: 0;
  border-width: 0.625rem 0.3125rem 0;
  border-top-color: #ddd;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.625rem;
}

.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
  left: 0;
  width: 0.625rem;
  height: 0.625rem;
}

.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
  right: 0;
  border-width: 0.3125rem 0.625rem 0.3125rem 0;
  border-right-color: #ddd;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.625rem 0;
}

.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0;
}

.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  bottom: 0;
  border-width: 0 0.3125rem 0.625rem;
  border-bottom-color: #ddd;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.625rem;
}

.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
  right: 0;
  width: 0.625rem;
  height: 0.625rem;
}

.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
  left: 0;
  border-width: 0.3125rem 0 0.3125rem 0.625rem;
  border-left-color: #ddd;
}

.tooltip-inner {
  max-width: 200px;
  padding: 1.25rem 1.25rem;
  color: #000;
  text-align: center;
  background-color: #ddd;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "HelvNeueOrange", "Helvetica Neue", Helvetica, Arial, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.25;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  font-weight: 700;
  word-wrap: break-word;
  background-color: #ddd;
  background-clip: padding-box;
  border: 2px solid #ddd;
}

.popover .arrow {
  position: absolute;
  display: block;
  width: 0.625rem;
  height: 0.3125rem;
  margin: 0 0.3rem;
}

.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.3125rem;
}

.bs-popover-top .arrow, .bs-popover-auto[x-placement^="top"] .arrow {
  bottom: calc((0.3125rem + 2px) * -1);
}

.bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before,
.bs-popover-top .arrow::after,
.bs-popover-auto[x-placement^="top"] .arrow::after {
  border-width: 0.3125rem 0.3125rem 0;
}

.bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before {
  bottom: 0;
  border-top-color: #ddd;
}


.bs-popover-top .arrow::after,
.bs-popover-auto[x-placement^="top"] .arrow::after {
  bottom: 2px;
  border-top-color: #ddd;
}

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.3125rem;
}

.bs-popover-right .arrow, .bs-popover-auto[x-placement^="right"] .arrow {
  left: calc((0.3125rem + 2px) * -1);
  width: 0.3125rem;
  height: 0.625rem;
  margin: 0.3rem 0;
}

.bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before,
.bs-popover-right .arrow::after,
.bs-popover-auto[x-placement^="right"] .arrow::after {
  border-width: 0.3125rem 0.3125rem 0.3125rem 0;
}

.bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before {
  left: 0;
  border-right-color: #ddd;
}


.bs-popover-right .arrow::after,
.bs-popover-auto[x-placement^="right"] .arrow::after {
  left: 2px;
  border-right-color: #ddd;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.3125rem;
}

.bs-popover-bottom .arrow, .bs-popover-auto[x-placement^="bottom"] .arrow {
  top: calc((0.3125rem + 2px) * -1);
}

.bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before,
.bs-popover-bottom .arrow::after,
.bs-popover-auto[x-placement^="bottom"] .arrow::after {
  border-width: 0 0.3125rem 0.3125rem 0.3125rem;
}

.bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before {
  top: 0;
  border-bottom-color: #ddd;
}


.bs-popover-bottom .arrow::after,
.bs-popover-auto[x-placement^="bottom"] .arrow::after {
  top: 2px;
  border-bottom-color: #ddd;
}

.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 0.625rem;
  margin-left: -0.3125rem;
  content: "";
  border-bottom: 2px solid #ddd;
}

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.3125rem;
}

.bs-popover-left .arrow, .bs-popover-auto[x-placement^="left"] .arrow {
  right: calc((0.3125rem + 2px) * -1);
  width: 0.3125rem;
  height: 0.625rem;
  margin: 0.3rem 0;
}

.bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before,
.bs-popover-left .arrow::after,
.bs-popover-auto[x-placement^="left"] .arrow::after {
  border-width: 0.3125rem 0 0.3125rem 0.3125rem;
}

.bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before {
  right: 0;
  border-left-color: #ddd;
}


.bs-popover-left .arrow::after,
.bs-popover-auto[x-placement^="left"] .arrow::after {
  right: 2px;
  border-left-color: #ddd;
}

.popover-header {
  padding: 0.625rem 0.625rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: inherit;
  background-color: #ddd;
  border-bottom: 2px solid #d0d0d0;
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.625rem 0.625rem;
  color: #000;
}

.carousel {
  position: relative;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-item {
  position: relative;
  display: none;
  align-items: center;
  width: 100%;
  transition: transform 0.6s ease;
  backface-visibility: hidden;
  perspective: 1000px;
}

@media screen and (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next,
.carousel-item-prev {
  position: absolute;
  top: 0;
}

.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
  transform: translateX(0);
}

@supports (transform-style: preserve-3d) {
  .carousel-item-next.carousel-item-left,
  .carousel-item-prev.carousel-item-right {
    transform: translate3d(0, 0, 0);
  }
}

.carousel-item-next,
.active.carousel-item-right {
  transform: translateX(100%);
}

@supports (transform-style: preserve-3d) {
  .carousel-item-next,
  .active.carousel-item-right {
    transform: translate3d(100%, 0, 0);
  }
}

.carousel-item-prev,
.active.carousel-item-left {
  transform: translateX(-100%);
}

@supports (transform-style: preserve-3d) {
  .carousel-item-prev,
  .active.carousel-item-left {
    transform: translate3d(-100%, 0, 0);
  }
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-duration: .6s;
  transition-property: opacity;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  opacity: 0;
}

.carousel-fade .carousel-item-next,
.carousel-fade .carousel-item-prev,
.carousel-fade .carousel-item.active,
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-prev {
  transform: translateX(0);
}

@supports (transform-style: preserve-3d) {
  .carousel-fade .carousel-item-next,
  .carousel-fade .carousel-item-prev,
  .carousel-fade .carousel-item.active,
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-prev {
    transform: translate3d(0, 0, 0);
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.875rem;
  color: #000;
  text-align: center;
  opacity: 0.5;
}

.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #000;
  text-decoration: none;
  outline: 0;
  opacity: .9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M4 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M1.5 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 10px;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 1.875rem;
  margin-left: 1.875rem;
  list-style: none;
}

.carousel-indicators li {
  position: relative;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: rgba(241, 110, 0, 0.5);
}

.carousel-indicators li::before {
  position: absolute;
  top: -10px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 10px;
  content: "";
}

.carousel-indicators li::after {
  position: absolute;
  bottom: -10px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 10px;
  content: "";
}

.carousel-indicators .active {
  background-color: #f16e00;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #000;
  text-align: center;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #f16e00 !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #be5700 !important;
}

.bg-secondary {
  background-color: #000 !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: black !important;
}

.bg-success {
  background-color: #32c832 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #289f28 !important;
}

.bg-info {
  background-color: #527edb !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #2b60cf !important;
}

.bg-warning {
  background-color: #fc0 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #cca300 !important;
}

.bg-danger {
  background-color: #cd3c14 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #9f2e0f !important;
}

.bg-light {
  background-color: #ddd !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #c4c4c4 !important;
}

.bg-dark {
  background-color: #000 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: black !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 2px solid #f6f6f6 !important;
}

.border-top {
  border-top: 2px solid #f6f6f6 !important;
}

.border-right {
  border-right: 2px solid #f6f6f6 !important;
}

.border-bottom {
  border-bottom: 2px solid #f6f6f6 !important;
}

.border-left {
  border-left: 2px solid #f6f6f6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #f16e00 !important;
}

.border-secondary {
  border-color: #000 !important;
}

.border-success {
  border-color: #32c832 !important;
}

.border-info {
  border-color: #527edb !important;
}

.border-warning {
  border-color: #fc0 !important;
}

.border-danger {
  border-color: #cd3c14 !important;
}

.border-light {
  border-color: #ddd !important;
}

.border-dark {
  border-color: #000 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 480px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 980px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1220px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1380px) {
  .d-xxl-none {
    display: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.857143%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 480px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 980px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1220px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1380px) {
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 480px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 980px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1220px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}

@media (min-width: 1380px) {
  .float-xxl-left {
    float: left !important;
  }
  .float-xxl-right {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 0;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.3125rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.3125rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.3125rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.3125rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.3125rem !important;
}

.m-2 {
  margin: 0.625rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.625rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.625rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.625rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.625rem !important;
}

.m-3 {
  margin: 1.25rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1.25rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1.25rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1.25rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1.25rem !important;
}

.m-4 {
  margin: 1.875rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.875rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.875rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.875rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.875rem !important;
}

.m-5 {
  margin: 3.75rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3.75rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3.75rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3.75rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3.75rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.3125rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.3125rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.3125rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.3125rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.3125rem !important;
}

.p-2 {
  padding: 0.625rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.625rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.625rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.625rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.625rem !important;
}

.p-3 {
  padding: 1.25rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1.25rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1.25rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1.25rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1.25rem !important;
}

.p-4 {
  padding: 1.875rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.875rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.875rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.875rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.875rem !important;
}

.p-5 {
  padding: 3.75rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3.75rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3.75rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3.75rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3.75rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 480px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.3125rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.3125rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.3125rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.3125rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.3125rem !important;
  }
  .m-sm-2 {
    margin: 0.625rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.625rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.625rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.625rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.625rem !important;
  }
  .m-sm-3 {
    margin: 1.25rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1.25rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1.25rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1.25rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1.25rem !important;
  }
  .m-sm-4 {
    margin: 1.875rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.875rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.875rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.875rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.875rem !important;
  }
  .m-sm-5 {
    margin: 3.75rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3.75rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3.75rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3.75rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3.75rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.3125rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.3125rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.3125rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.3125rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.3125rem !important;
  }
  .p-sm-2 {
    padding: 0.625rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.625rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.625rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.625rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.625rem !important;
  }
  .p-sm-3 {
    padding: 1.25rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1.25rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1.25rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1.25rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1.25rem !important;
  }
  .p-sm-4 {
    padding: 1.875rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.875rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.875rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.875rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.875rem !important;
  }
  .p-sm-5 {
    padding: 3.75rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3.75rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3.75rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3.75rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3.75rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.3125rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.3125rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.3125rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.3125rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.3125rem !important;
  }
  .m-md-2 {
    margin: 0.625rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.625rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.625rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.625rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.625rem !important;
  }
  .m-md-3 {
    margin: 1.25rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1.25rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1.25rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1.25rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1.25rem !important;
  }
  .m-md-4 {
    margin: 1.875rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.875rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.875rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.875rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.875rem !important;
  }
  .m-md-5 {
    margin: 3.75rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3.75rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3.75rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3.75rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3.75rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.3125rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.3125rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.3125rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.3125rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.3125rem !important;
  }
  .p-md-2 {
    padding: 0.625rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.625rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.625rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.625rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.625rem !important;
  }
  .p-md-3 {
    padding: 1.25rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1.25rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1.25rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1.25rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1.25rem !important;
  }
  .p-md-4 {
    padding: 1.875rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.875rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.875rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.875rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.875rem !important;
  }
  .p-md-5 {
    padding: 3.75rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3.75rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3.75rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3.75rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3.75rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 980px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.3125rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.3125rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.3125rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.3125rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.3125rem !important;
  }
  .m-lg-2 {
    margin: 0.625rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.625rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.625rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.625rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.625rem !important;
  }
  .m-lg-3 {
    margin: 1.25rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1.25rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1.25rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1.25rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1.25rem !important;
  }
  .m-lg-4 {
    margin: 1.875rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.875rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.875rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.875rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.875rem !important;
  }
  .m-lg-5 {
    margin: 3.75rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3.75rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3.75rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3.75rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3.75rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.3125rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.3125rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.3125rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.3125rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.3125rem !important;
  }
  .p-lg-2 {
    padding: 0.625rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.625rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.625rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.625rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.625rem !important;
  }
  .p-lg-3 {
    padding: 1.25rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1.25rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1.25rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1.25rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1.25rem !important;
  }
  .p-lg-4 {
    padding: 1.875rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.875rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.875rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.875rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.875rem !important;
  }
  .p-lg-5 {
    padding: 3.75rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3.75rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3.75rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3.75rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3.75rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1220px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.3125rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.3125rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.3125rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.3125rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.3125rem !important;
  }
  .m-xl-2 {
    margin: 0.625rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.625rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.625rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.625rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.625rem !important;
  }
  .m-xl-3 {
    margin: 1.25rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1.25rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1.25rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1.25rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1.25rem !important;
  }
  .m-xl-4 {
    margin: 1.875rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.875rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.875rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.875rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.875rem !important;
  }
  .m-xl-5 {
    margin: 3.75rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3.75rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3.75rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3.75rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3.75rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.3125rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.3125rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.3125rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.3125rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.3125rem !important;
  }
  .p-xl-2 {
    padding: 0.625rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.625rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.625rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.625rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.625rem !important;
  }
  .p-xl-3 {
    padding: 1.25rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1.25rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1.25rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1.25rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1.25rem !important;
  }
  .p-xl-4 {
    padding: 1.875rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.875rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.875rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.875rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.875rem !important;
  }
  .p-xl-5 {
    padding: 3.75rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3.75rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3.75rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3.75rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3.75rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1380px) {
  .m-xxl-0 {
    margin: 0 !important;
  }
  .mt-xxl-0,
  .my-xxl-0 {
    margin-top: 0 !important;
  }
  .mr-xxl-0,
  .mx-xxl-0 {
    margin-right: 0 !important;
  }
  .mb-xxl-0,
  .my-xxl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xxl-0,
  .mx-xxl-0 {
    margin-left: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.3125rem !important;
  }
  .mt-xxl-1,
  .my-xxl-1 {
    margin-top: 0.3125rem !important;
  }
  .mr-xxl-1,
  .mx-xxl-1 {
    margin-right: 0.3125rem !important;
  }
  .mb-xxl-1,
  .my-xxl-1 {
    margin-bottom: 0.3125rem !important;
  }
  .ml-xxl-1,
  .mx-xxl-1 {
    margin-left: 0.3125rem !important;
  }
  .m-xxl-2 {
    margin: 0.625rem !important;
  }
  .mt-xxl-2,
  .my-xxl-2 {
    margin-top: 0.625rem !important;
  }
  .mr-xxl-2,
  .mx-xxl-2 {
    margin-right: 0.625rem !important;
  }
  .mb-xxl-2,
  .my-xxl-2 {
    margin-bottom: 0.625rem !important;
  }
  .ml-xxl-2,
  .mx-xxl-2 {
    margin-left: 0.625rem !important;
  }
  .m-xxl-3 {
    margin: 1.25rem !important;
  }
  .mt-xxl-3,
  .my-xxl-3 {
    margin-top: 1.25rem !important;
  }
  .mr-xxl-3,
  .mx-xxl-3 {
    margin-right: 1.25rem !important;
  }
  .mb-xxl-3,
  .my-xxl-3 {
    margin-bottom: 1.25rem !important;
  }
  .ml-xxl-3,
  .mx-xxl-3 {
    margin-left: 1.25rem !important;
  }
  .m-xxl-4 {
    margin: 1.875rem !important;
  }
  .mt-xxl-4,
  .my-xxl-4 {
    margin-top: 1.875rem !important;
  }
  .mr-xxl-4,
  .mx-xxl-4 {
    margin-right: 1.875rem !important;
  }
  .mb-xxl-4,
  .my-xxl-4 {
    margin-bottom: 1.875rem !important;
  }
  .ml-xxl-4,
  .mx-xxl-4 {
    margin-left: 1.875rem !important;
  }
  .m-xxl-5 {
    margin: 3.75rem !important;
  }
  .mt-xxl-5,
  .my-xxl-5 {
    margin-top: 3.75rem !important;
  }
  .mr-xxl-5,
  .mx-xxl-5 {
    margin-right: 3.75rem !important;
  }
  .mb-xxl-5,
  .my-xxl-5 {
    margin-bottom: 3.75rem !important;
  }
  .ml-xxl-5,
  .mx-xxl-5 {
    margin-left: 3.75rem !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .pt-xxl-0,
  .py-xxl-0 {
    padding-top: 0 !important;
  }
  .pr-xxl-0,
  .px-xxl-0 {
    padding-right: 0 !important;
  }
  .pb-xxl-0,
  .py-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xxl-0,
  .px-xxl-0 {
    padding-left: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.3125rem !important;
  }
  .pt-xxl-1,
  .py-xxl-1 {
    padding-top: 0.3125rem !important;
  }
  .pr-xxl-1,
  .px-xxl-1 {
    padding-right: 0.3125rem !important;
  }
  .pb-xxl-1,
  .py-xxl-1 {
    padding-bottom: 0.3125rem !important;
  }
  .pl-xxl-1,
  .px-xxl-1 {
    padding-left: 0.3125rem !important;
  }
  .p-xxl-2 {
    padding: 0.625rem !important;
  }
  .pt-xxl-2,
  .py-xxl-2 {
    padding-top: 0.625rem !important;
  }
  .pr-xxl-2,
  .px-xxl-2 {
    padding-right: 0.625rem !important;
  }
  .pb-xxl-2,
  .py-xxl-2 {
    padding-bottom: 0.625rem !important;
  }
  .pl-xxl-2,
  .px-xxl-2 {
    padding-left: 0.625rem !important;
  }
  .p-xxl-3 {
    padding: 1.25rem !important;
  }
  .pt-xxl-3,
  .py-xxl-3 {
    padding-top: 1.25rem !important;
  }
  .pr-xxl-3,
  .px-xxl-3 {
    padding-right: 1.25rem !important;
  }
  .pb-xxl-3,
  .py-xxl-3 {
    padding-bottom: 1.25rem !important;
  }
  .pl-xxl-3,
  .px-xxl-3 {
    padding-left: 1.25rem !important;
  }
  .p-xxl-4 {
    padding: 1.875rem !important;
  }
  .pt-xxl-4,
  .py-xxl-4 {
    padding-top: 1.875rem !important;
  }
  .pr-xxl-4,
  .px-xxl-4 {
    padding-right: 1.875rem !important;
  }
  .pb-xxl-4,
  .py-xxl-4 {
    padding-bottom: 1.875rem !important;
  }
  .pl-xxl-4,
  .px-xxl-4 {
    padding-left: 1.875rem !important;
  }
  .p-xxl-5 {
    padding: 3.75rem !important;
  }
  .pt-xxl-5,
  .py-xxl-5 {
    padding-top: 3.75rem !important;
  }
  .pr-xxl-5,
  .px-xxl-5 {
    padding-right: 3.75rem !important;
  }
  .pb-xxl-5,
  .py-xxl-5 {
    padding-bottom: 3.75rem !important;
  }
  .pl-xxl-5,
  .px-xxl-5 {
    padding-left: 3.75rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mt-xxl-auto,
  .my-xxl-auto {
    margin-top: auto !important;
  }
  .mr-xxl-auto,
  .mx-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-auto,
  .my-xxl-auto {
    margin-bottom: auto !important;
  }
  .ml-xxl-auto,
  .mx-xxl-auto {
    margin-left: auto !important;
  }
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

.text-justify {
  text-align: justify !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 480px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 980px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1220px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

@media (min-width: 1380px) {
  .text-xxl-left {
    text-align: left !important;
  }
  .text-xxl-right {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #f16e00 !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #be5700 !important;
}

.text-secondary {
  color: #000 !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: black !important;
}

.text-success {
  color: #32c832 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #289f28 !important;
}

.text-info {
  color: #527edb !important;
}

a.text-info:hover, a.text-info:focus {
  color: #2b60cf !important;
}

.text-warning {
  color: #fc0 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #cca300 !important;
}

.text-danger {
  color: #cd3c14 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #9f2e0f !important;
}

.text-light {
  color: #ddd !important;
}

a.text-light:hover, a.text-light:focus {
  color: #c4c4c4 !important;
}

.text-dark {
  color: #000 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: black !important;
}

.text-body {
  color: #000 !important;
}

.text-muted {
  color: #666 !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 2px solid #ccc;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 980px !important;
  }
  .container {
    min-width: 980px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 2px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #eee !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #ccc;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #ccc;
  }
}

.card-deck .o-card-link {
  margin: 0 1rem;
}

.o-card-link .card-title {
  align-self: center;
  width: 100%;
  margin: auto 0;
  font-weight: 700;
  word-break: normal;
}

.o-card-link .card-img {
  padding: 0;
  text-align: center;
}

.o-card-link .card-img img {
  width: 100%;
  height: auto;
}

.o-card-link .card-body {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.card-deck {
  margin: 0;
}

@media (max-width: 479.98px) {
  .card.o-card-link {
    flex-basis: calc(50% - 2rem);
    margin: 1rem;
  }
  .card-deck {
    flex-direction: column;
  }
  .card-deck .o-card-link,
  .o-card-link {
    flex-direction: row;
    height: 3.75rem;
    margin: 0;
    margin-bottom: .625rem;
    border: 0;
  }
  .o-card-link .card-img {
    flex-basis: 20%;
    width: auto;
    padding: 0;
  }
  .o-card-link .card-img img {
    width: auto;
    height: 3.75rem;
  }
  .o-card-link .card-body {
    flex-basis: 80%;
    align-self: center;
    padding: 0 1rem;
    text-align: left;
  }
  .o-card-link .card-title {
    align-self: flex-start;
    margin: 0;
  }
  .o-card-link .card-title::after {
    position: absolute;
    top: calc(50% - .5rem);
    right: .25rem;
    width: 1rem;
    height: 1rem;
    content: "";
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 15 24'%3E%3Cpath d='M3 0L0 3l9 9-9 9 3 3 12-12' fill='%23999'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
  }
}

@media (min-width: 480px) and (max-width: 767.98px) {
  .card.o-card-link {
    flex-basis: calc(50% - 2rem);
    margin: 1rem;
  }
}

.o-switch .toggle,
.o-switch .toggle::before,
.o-switch .toggle .on,
.o-switch .toggle .off {
  transition: .4s;
}

.o-switch .toggle {
  position: relative;
  display: block;
  width: auto;
  min-width: 3.75rem;
  height: 1.875rem;
  margin: .3125rem 0;
  background: #666;
  border: 2px solid #666;
}

.o-switch .toggle.toggle-rounded, .o-switch .toggle.toggle-rounded::before {
  border-radius: 1.625rem;
}

.o-switch .toggle .on,
.o-switch .toggle .off {
  display: none;
  font-weight: 700;
  line-height: 1.625rem;
  color: #fff;
  text-align: left;
  cursor: default;
  opacity: 0;
}

.o-switch .toggle .on.svg-checkbox-tick::after {
  top: .1875rem;
  left: -.125rem;
}

.o-switch .toggle .off {
  display: block;
  text-align: right;
  opacity: 1;
}

.o-switch .toggle .off.svg-delete::after {
  top: .1875rem;
  right: -.125rem;
  left: auto;
}

.o-switch .toggle::before {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  display: block;
  width: 1.625rem;
  height: 1.625rem;
  content: "";
  background: #fff;
}

.o-switch .checkbox:focus + .toggle::before {
  outline: 1px dotted #fff;
}

.o-switch .checkbox:checked + .toggle {
  background: #f16e00;
  border-color: #f16e00;
}

.o-switch .checkbox:checked + .toggle .on {
  display: block;
  opacity: 1;
}

.o-switch .checkbox:checked + .toggle .off {
  display: none;
  opacity: 0;
}

.o-switch .checkbox:checked + .toggle::before {
  right: 0;
  left: auto;
}

.o-switch .checkbox.success:checked + .toggle {
  background: #32c832;
  border-color: #32c832;
}

.o-switch .checkbox.success:checked + .toggle .on {
  color: #000;
}

.o-switch .checkbox.success:checked + .toggle .on.icon-checkbox-tick {
  color: #fff;
}

.form-group.row .o-switch .col-form-label.toggle {
  padding: 0 .375rem;
}

.form-group.row .o-switch .col-form-label.toggle.toggle-rounded {
  padding: 0 .875rem;
}

.o-switch.btn-group-toggle {
  border: 2px solid #000;
}

.o-switch.btn-group-toggle .btn {
  width: 2.5rem;
  padding-right: 0;
  padding-left: 0;
  margin: 0;
  border-top: 0;
  border-right: 2px solid #fff;
  border-bottom: 0;
  border-left: 2px solid #fff;
}

.o-switch.btn-group-toggle .btn:first-child {
  margin-left: -2px;
  border-left-color: #000;
}

.o-switch.btn-group-toggle .btn:last-child {
  margin-right: -2px;
  border-right-color: #000;
}

.o-switch.btn-group-toggle .btn.active {
  color: #fff;
  background-color: #000;
  border-right-color: #000;
  border-left-color: #000;
}

.o-switch.btn-group-toggle .btn.focus {
  outline: 1px dotted #fff;
  outline-offset: -2px;
}

.o-switch.btn-group-toggle .btn:hover {
  color: #f16e00;
  background-color: #fff;
}

.o-switch.btn-group-toggle .btn:hover.active {
  color: #fff;
  background-color: #000;
  border-right-color: #000;
  border-left-color: #000;
}

.o-switch.btn-group-toggle.no-outline {
  border-color: transparent;
}

.o-switch.btn-group-toggle.no-outline .btn {
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
}

.o-switch.btn-group-toggle.no-outline .btn:first-child {
  border-left-color: transparent;
}

.o-switch.btn-group-toggle.no-outline .btn:last-child {
  border-right-color: transparent;
}

.o-switch.btn-group-toggle.no-outline .btn.active {
  color: #f16e00;
  background-color: #fff;
  border-color: transparent;
  outline-color: #000;
}

.o-switch.btn-group-toggle.no-outline .btn:hover {
  color: #000;
  background-color: #fff;
  border-color: #000;
}

.o-switch.btn-group-toggle.no-outline .btn:hover.active {
  color: #f16e00;
  background-color: #fff;
  border-color: transparent;
  outline-color: #000;
}

.form-group input[type="radio"],
.form-group input[type="checkbox"] {
  width: auto;
}

.form-group.row .form-control-feedback {
  padding-left: 0;
}

.form-group.row.has-danger.top {
  flex-direction: row;
}

.form-group.row.has-danger.top label,
.form-group.row.has-danger.top .col-form-label {
  order: 1;
}

.form-group.row.has-danger.top .form-control {
  order: 2;
  margin-top: 0;
}

.form-group.row.has-danger.top .form-control-feedback {
  order: 0;
}

.form-group.row.has-danger.right label,
.form-group.row.has-danger.right .col-form-label {
  min-width: auto;
}

.form-group.row.has-danger.right .form-control-feedback {
  align-self: center;
  margin-top: 0;
}

.form-group.is-required .col-form-label::after {
  margin-left: 6px;
  font-weight: 700;
  color: #f16e00;
  content: "*";
}

.form-group .form-control:invalid {
  outline: none;
  box-shadow: none;
}

.form-group.has-danger .col-form-label, .form-group.has-warning .col-form-label, .form-group.has-success .col-form-label {
  color: #000;
}

.form-group .form-control-feedback {
  margin-top: .625rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1;
}

.form-group.has-danger {
  position: relative;
}

.form-group.has-danger .form-control-feedback {
  margin-top: .8125rem;
  font-weight: 700;
  color: #cd3c14;
}

.form-group.has-danger .form-control-danger {
  background-image: none;
}

.form-group.has-danger.top {
  display: flex;
  flex-direction: column;
}

.form-group.has-danger.top .col-form-label {
  margin-bottom: 0;
}

.form-group.has-danger.top .form-control {
  order: 2;
}

.form-group.has-danger.top .form-control-feedback {
  order: 1;
  margin-top: 0;
  margin-bottom: .8125rem;
}

.form-group.has-danger.right {
  display: flex;
  flex-flow: row wrap;
}

.form-group.has-danger.right label,
.form-group.has-danger.right .col-form-label {
  min-width: 100%;
}

.form-group.has-danger.right .form-control-message,
.form-group.has-danger.right .form-control-feedback {
  margin-left: .625rem;
}

select.form-control:not([size]):not([multiple]) {
  height: auto;
  padding-top: .4375rem;
  padding-bottom: .4375rem;
}

select.form-control:not([size]):not([multiple]).form-control-lg, .input-group-lg > select.form-control:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.form-control.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.form-control.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.form-control.btn:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.form-control.btn:not([size]):not([multiple]) {
  padding-top: .65625rem;
  padding-bottom: .65625rem;
}

select.form-control:not([size]):not([multiple]).form-control-sm, .input-group-sm > select.form-control:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.form-control.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.form-control.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.form-control.btn:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.form-control.btn:not([size]):not([multiple]) {
  padding-top: .1875rem;
  padding-bottom: .1875rem;
}

.has-feedback label ~ .form-control-feedback {
  top: 1.75;
}

.o-square-list {
  padding-left: 0;
  color: #f16e00;
  text-align: left;
}

.o-square-list,
.o-square-list ul * {
  list-style-type: none;
}

.o-square-list li {
  color: #000;
}

.o-square-list li::before {
  margin-right: 1rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #f16e00;
  vertical-align: text-top;
  content: "\25A0";
}

.o-square-list li span {
  display: inline-block;
  margin-bottom: .625rem;
}

.o-square-list li > ul {
  padding-left: 1rem;
}

.o-square-list ul > li::before {
  color: #999;
}

.o-square-list ul ul > li::before {
  color: #ccc;
}

.o-stepbar,
.o-stepbar ol,
.o-stepbar ul {
  padding: 0;
  margin: 0;
  overflow: hidden;
}

.o-stepbar .stepbar-item:first-child:nth-last-child(1) {
  width: 100%;
}

.o-stepbar .stepbar-item:first-child:nth-last-child(2),
.o-stepbar .stepbar-item:first-child:nth-last-child(2) ~ .stepbar-item {
  width: 50%;
}

.o-stepbar .stepbar-item:first-child:nth-last-child(3),
.o-stepbar .stepbar-item:first-child:nth-last-child(3) ~ .stepbar-item {
  width: 33.3333%;
}

.o-stepbar .stepbar-item:first-child:nth-last-child(4),
.o-stepbar .stepbar-item:first-child:nth-last-child(4) ~ .stepbar-item {
  width: 25%;
}

.o-stepbar .stepbar-item:first-child:nth-last-child(5),
.o-stepbar .stepbar-item:first-child:nth-last-child(5) ~ .stepbar-item {
  width: 20%;
}

.o-stepbar .stepbar-item {
  position: relative;
  float: left;
  padding: 0.625rem 1.25rem;
  padding-left: 2rem;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.25;
  color: #000;
  white-space: nowrap;
  list-style: none;
  background-color: #ddd;
}

.o-stepbar .stepbar-item::after {
  position: absolute;
  top: 0;
  right: -1rem;
  z-index: 500;
  display: block;
  width: 1rem;
  height: 2.5rem;
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2240%22%20viewBox%3D%220%200%2016%2040%22%3E%3Cpath%20d%3D%22M0%200l14%2020L0%2040z%22%20fill%3D%22%23dddddd%22%2F%3E%3Cpath%20d%3D%22M2%200H0l14%2020L0%2040h2l14-20z%22%20fill%3D%22%23fff%22%2F%3E%3C%2Fsvg%3E");
  background-size: 1rem 2.5rem;
}

.o-stepbar .stepbar-item:first-child {
  padding-left: 1.25rem;
}

.o-stepbar .stepbar-item:last-child::after {
  display: none;
}

.o-stepbar .stepbar-item.done {
  background-color: #000;
}

.o-stepbar .stepbar-item.done::after {
  position: absolute;
  top: 0;
  right: -1rem;
  z-index: 500;
  display: block;
  width: 1rem;
  height: 2.5rem;
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2240%22%20viewBox%3D%220%200%2016%2040%22%3E%3Cpath%20d%3D%22M0%200l14%2020L0%2040z%22%20fill%3D%22%23000000%22%2F%3E%3Cpath%20d%3D%22M2%200H0l14%2020L0%2040h2l14-20z%22%20fill%3D%22%23fff%22%2F%3E%3C%2Fsvg%3E");
  background-size: 1rem 2.5rem;
}

.o-stepbar .stepbar-item.done .stepbar-link {
  color: #fff;
}

.o-stepbar .stepbar-item.done:last-child::after {
  display: none;
}

.o-stepbar .stepbar-item.current {
  background-color: #f16e00;
}

.o-stepbar .stepbar-item.current::after {
  position: absolute;
  top: 0;
  right: -1rem;
  z-index: 500;
  display: block;
  width: 1rem;
  height: 2.5rem;
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2240%22%20viewBox%3D%220%200%2016%2040%22%3E%3Cpath%20d%3D%22M0%200l14%2020L0%2040z%22%20fill%3D%22%23f16e00%22%2F%3E%3Cpath%20d%3D%22M2%200H0l14%2020L0%2040h2l14-20z%22%20fill%3D%22%23fff%22%2F%3E%3C%2Fsvg%3E");
  background-size: 1rem 2.5rem;
}

.o-stepbar .stepbar-item.current .stepbar-link {
  color: #fff;
}

.o-stepbar .stepbar-item.current:last-child::after {
  display: none;
}

.o-stepbar .stepbar-item .stepbar-link {
  text-decoration: none;
}

.o-stepbar .stepbar-item .stepbar-link .step-number::after {
  content: ".";
}

@media (max-width: 479.98px) {
  .o-stepbar .stepbar-item {
    padding: 0.3125rem 0.625rem 0.3125rem 2rem;
    line-height: 1.25;
  }
  .o-stepbar .stepbar-item::after,
  .o-stepbar .stepbar-item.done::after,
  .o-stepbar .stepbar-item.current::after,
  .o-stepbar .stepbar-item.next::after {
    right: -0.6875rem;
    width: 0.75rem;
    height: 1.875rem;
    background-size: cover;
  }
  .o-stepbar.xs-numbers .step-number::after {
    display: none;
  }
  .o-stepbar.xs-numbers .stepbar-item:first-child:nth-last-child(3),
  .o-stepbar.xs-numbers .stepbar-item:first-child:nth-last-child(3) ~ .stepbar-item {
    width: 20%;
  }
  .o-stepbar.xs-numbers .stepbar-item:first-child:nth-last-child(3).current,
  .o-stepbar.xs-numbers .stepbar-item:first-child:nth-last-child(3) ~ .stepbar-item.current {
    width: 60%;
  }
  .o-stepbar.xs-numbers .stepbar-item:first-child:nth-last-child(4),
  .o-stepbar.xs-numbers .stepbar-item:first-child:nth-last-child(4) ~ .stepbar-item,
  .o-stepbar.xs-numbers .stepbar-item:first-child:nth-last-child(5),
  .o-stepbar.xs-numbers .stepbar-item:first-child:nth-last-child(5) ~ .stepbar-item {
    width: 20%;
  }
  .o-stepbar.xs-numbers .stepbar-item:first-child:nth-last-child(4).current,
  .o-stepbar.xs-numbers .stepbar-item:first-child:nth-last-child(4) ~ .stepbar-item.current,
  .o-stepbar.xs-numbers .stepbar-item:first-child:nth-last-child(5).current,
  .o-stepbar.xs-numbers .stepbar-item:first-child:nth-last-child(5) ~ .stepbar-item.current {
    width: 40%;
  }
  .o-stepbar.xs-numbers .stepbar-item {
    width: 20%;
    padding-right: 0;
    padding-left: 0.75rem;
    text-align: center;
  }
  .o-stepbar.xs-numbers .stepbar-item:first-child {
    padding-left: 0;
  }
  .o-stepbar.xs-numbers .stepbar-item.current {
    width: 60%;
  }
  .o-stepbar.xs-numbers .stepbar-item.current .step-number {
    display: none;
  }
  .o-stepbar.xs-numbers .stepbar-item:not(.current) .step-title {
    display: none;
  }
  .o-stepbar.xs-labels .step-number {
    display: none;
  }
}

.o-link-arrow {
  font-size: 1rem;
  font-weight: 700;
  text-decoration: none;
}

.o-link-arrow::after {
  padding: 0 0.625rem;
  font-family: icon-orange, sans-serif;
  font-size: 0.625rem;
  color: #000;
  content: "\e93e";
}

.o-link-arrow:hover::after, .o-link-arrow:focus::after {
  color: #f16e00;
  text-decoration: none;
}

.o-link-arrow.back::after {
  padding: 0;
  content: "";
}

.o-link-arrow.back::before {
  padding: 0 0.625rem 0 0;
  font-family: icon-orange, sans-serif;
  font-size: 0.625rem;
  color: #000;
  content: "\e93f";
}

.o-link-arrow.back:hover::before, .o-link-arrow.back:focus::before {
  color: #f16e00;
  text-decoration: none;
}

.mega-menu {
  overflow: hidden;
}

.mega-menu > .navbar-nav {
  transition: transform .5s;
  transform: translateX(0);
}

.mega-menu-panel > .navbar-nav,
.mega-menu-panel > .container > .navbar-nav {
  flex-direction: column;
}

.mega-menu-panel > .navbar-nav .navbar-nav,
.mega-menu-panel > .container > .navbar-nav .navbar-nav {
  position: absolute;
  top: 0;
  left: 100%;
  display: none;
  width: 100%;
}

.fixed-header {
  position: fixed;
  top: 0;
  z-index: 1030;
  width: 100%;
}

.navbar .nav-link.icon {
  margin-top: 0;
}

.navbar .nav-link.icon span[class^="icon-"] {
  font-size: 1.5rem;
  font-weight: 400;
}

.navbar .nav-link.icon[class*="svg-"], .navbar .nav-link.icon[class*="svg-"]::after {
  width: 1.875rem;
  height: 1.875rem;
}

.navbar .nav-link:not(.icon)[class*="svg-"]::after {
  top: 0;
  right: 0;
  left: auto;
}

.navbar.supra {
  height: 2.5rem;
  min-height: 2.5rem;
  padding-top: 0;
  padding-bottom: 0;
  border-bottom: 1px solid #333;
}

@media (max-width: 479.98px) {
  .navbar.supra {
    display: none;
  }
}

.navbar.supra > .container,
.navbar.supra > .container .navbar-nav {
  align-items: center;
}

.navbar.supra .navbar-brand {
  display: none;
}

.navbar.supra .navbar-nav {
  margin: 0;
}

.navbar.supra .navbar-nav > .nav-link:first-child,
.navbar.supra .navbar-nav .nav-item:first-child .nav-link {
  margin-left: 0;
}

.navbar.supra .nav-link,
.navbar.supra .dropdown-toggle {
  font-size: 0.875rem;
  line-height: 1;
}

.navbar.supra .nav-link:focus,
.navbar.supra .dropdown-toggle:focus {
  outline-offset: .5rem;
}

.navbar.supra .nav-link span[class^="icon-"],
.navbar.supra .dropdown-toggle span[class^="icon-"] {
  font-size: 1.125rem;
  font-weight: 400;
}

.navbar.supra .nav-link.icon span[class^="icon-"] {
  font-size: 1rem;
  font-weight: 400;
}

.navbar.supra .nav-link.icon[class*="svg-"], .navbar.supra .nav-link.icon[class*="svg-"]::after {
  width: 1.25rem;
  height: 1.25rem;
}

.navbar.supra .dropdown-menu {
  right: 0;
  left: auto;
}

@media (max-width: 479.98px) {
  .navbar-expand-sm .mega-menu .nav-link, .navbar-expand-sm.minimized .mega-menu .nav-link {
    padding-right: 0;
    outline-offset: -1px;
  }
  .navbar-expand-sm .mega-menu .nav-link[data-toggle="collapse"]::before, .navbar-expand-sm.minimized .mega-menu .nav-link[data-toggle="collapse"]::before {
    margin-top: .3125rem;
    margin-right: .9375rem;
    content: "";
    border-top: .4375em solid;
    border-right: .4375em solid transparent;
    border-left: .4375em solid transparent;
    transform: rotateZ(180deg);
  }
  .navbar-expand-sm .mega-menu .nav-link[data-toggle="collapse"].collapsed::before, .navbar-expand-sm.minimized .mega-menu .nav-link[data-toggle="collapse"].collapsed::before {
    transform: rotateZ(0deg);
  }
  .navbar-expand-sm .mega-menu-panel .nav-link.back, .navbar-expand-sm.minimized .mega-menu-panel .nav-link.back {
    display: block;
  }
  .navbar-expand-sm .mega-menu-panel .nav-link.back::before, .navbar-expand-sm.minimized .mega-menu-panel .nav-link.back::before {
    float: left;
    transform: rotateZ(180deg);
  }
  .navbar-expand-sm .mega-menu-panel > .container, .navbar-expand-sm.minimized .mega-menu-panel > .container {
    width: 100%;
    padding: 0;
    margin: 0;
  }
  .navbar-expand-sm .mega-menu-panel > .container a[data-toggle="collapse"], .navbar-expand-sm.minimized .mega-menu-panel > .container a[data-toggle="collapse"] {
    display: none;
  }
  .navbar-expand-sm .mega-menu-panel > .container > .navbar-nav > .nav-item, .navbar-expand-sm.minimized .mega-menu-panel > .container > .navbar-nav > .nav-item {
    padding: 0;
    border: 0;
  }
  .navbar-expand-sm .mega-menu-panel > .container > .navbar-nav .navbar-nav .nav-item:last-child, .navbar-expand-sm.minimized .mega-menu-panel > .container > .navbar-nav .navbar-nav .nav-item:last-child {
    border-bottom: 1px solid #333;
  }
}

@media (min-width: 480px) {
  .navbar-expand-sm .mega-menu {
    overflow: visible;
  }
  .navbar-expand-sm .mega-menu > .navbar-nav {
    transition: none;
    transform: none;
  }
  .navbar-expand-sm .mega-menu > .navbar-nav .nav-link::before {
    display: none;
  }
  .navbar-expand-sm .mega-menu-panel {
    position: absolute;
    top: 100px;
    left: 0;
    z-index: 1030;
    width: 100%;
    padding: 1rem 0;
    background-color: #000;
    border-top: 1px solid #333;
  }
  .navbar-expand-sm .mega-menu-panel a[data-toggle="collapse"] {
    display: block;
    float: right;
    margin: 1.25rem;
    color: #fff;
  }
  .navbar-expand-sm .mega-menu-panel .nav-link {
    margin-left: 0;
  }
  .navbar-expand-sm .mega-menu-panel .svg-delete {
    width: 1.25rem;
    height: 1.25rem;
  }
  .navbar-expand-sm .mega-menu-panel > .container > .navbar-nav {
    flex-direction: row;
    align-items: flex-start;
  }
  .navbar-expand-sm .mega-menu-panel > .container > .navbar-nav > .nav-item {
    flex-grow: 1;
    padding: 1rem 0;
  }
  .navbar-expand-sm .mega-menu-panel > .container > .navbar-nav > .nav-item > .nav-link {
    padding: 0;
    margin-right: .625rem;
    margin-bottom: 1rem;
    font-size: 1.25rem;
  }
  .navbar-expand-sm .mega-menu-panel > .container > .navbar-nav .navbar-nav {
    position: static;
    display: block !important;
    flex-direction: column;
    align-items: flex-start;
  }
  .navbar-expand-sm .mega-menu-panel > .container > .navbar-nav .navbar-nav .nav-link {
    padding: 0;
    line-height: 1.25rem;
    outline-offset: 1px;
  }
  .navbar-expand-sm .mega-menu-panel > .container > .navbar-nav .nav-item:first-child,
  .navbar-expand-sm .mega-menu-panel > .container > .navbar-nav .nav-item + .nav-item {
    margin-left: 0;
  }
  .navbar-expand-sm .mega-menu-panel > .container > .navbar-nav .nav-link.back {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md .mega-menu .nav-link, .navbar-expand-md.minimized .mega-menu .nav-link {
    padding-right: 0;
    outline-offset: -1px;
  }
  .navbar-expand-md .mega-menu .nav-link[data-toggle="collapse"]::before, .navbar-expand-md.minimized .mega-menu .nav-link[data-toggle="collapse"]::before {
    margin-top: .3125rem;
    margin-right: .9375rem;
    content: "";
    border-top: .4375em solid;
    border-right: .4375em solid transparent;
    border-left: .4375em solid transparent;
    transform: rotateZ(180deg);
  }
  .navbar-expand-md .mega-menu .nav-link[data-toggle="collapse"].collapsed::before, .navbar-expand-md.minimized .mega-menu .nav-link[data-toggle="collapse"].collapsed::before {
    transform: rotateZ(0deg);
  }
  .navbar-expand-md .mega-menu-panel .nav-link.back, .navbar-expand-md.minimized .mega-menu-panel .nav-link.back {
    display: block;
  }
  .navbar-expand-md .mega-menu-panel .nav-link.back::before, .navbar-expand-md.minimized .mega-menu-panel .nav-link.back::before {
    float: left;
    transform: rotateZ(180deg);
  }
  .navbar-expand-md .mega-menu-panel > .container, .navbar-expand-md.minimized .mega-menu-panel > .container {
    width: 100%;
    padding: 0;
    margin: 0;
  }
  .navbar-expand-md .mega-menu-panel > .container a[data-toggle="collapse"], .navbar-expand-md.minimized .mega-menu-panel > .container a[data-toggle="collapse"] {
    display: none;
  }
  .navbar-expand-md .mega-menu-panel > .container > .navbar-nav > .nav-item, .navbar-expand-md.minimized .mega-menu-panel > .container > .navbar-nav > .nav-item {
    padding: 0;
    border: 0;
  }
  .navbar-expand-md .mega-menu-panel > .container > .navbar-nav .navbar-nav .nav-item:last-child, .navbar-expand-md.minimized .mega-menu-panel > .container > .navbar-nav .navbar-nav .nav-item:last-child {
    border-bottom: 1px solid #333;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md .mega-menu {
    overflow: visible;
  }
  .navbar-expand-md .mega-menu > .navbar-nav {
    transition: none;
    transform: none;
  }
  .navbar-expand-md .mega-menu > .navbar-nav .nav-link::before {
    display: none;
  }
  .navbar-expand-md .mega-menu-panel {
    position: absolute;
    top: 100px;
    left: 0;
    z-index: 1030;
    width: 100%;
    padding: 1rem 0;
    background-color: #000;
    border-top: 1px solid #333;
  }
  .navbar-expand-md .mega-menu-panel a[data-toggle="collapse"] {
    display: block;
    float: right;
    margin: 1.25rem;
    color: #fff;
  }
  .navbar-expand-md .mega-menu-panel .nav-link {
    margin-left: 0;
  }
  .navbar-expand-md .mega-menu-panel .svg-delete {
    width: 1.25rem;
    height: 1.25rem;
  }
  .navbar-expand-md .mega-menu-panel > .container > .navbar-nav {
    flex-direction: row;
    align-items: flex-start;
  }
  .navbar-expand-md .mega-menu-panel > .container > .navbar-nav > .nav-item {
    flex-grow: 1;
    padding: 1rem 0;
  }
  .navbar-expand-md .mega-menu-panel > .container > .navbar-nav > .nav-item > .nav-link {
    padding: 0;
    margin-right: .625rem;
    margin-bottom: 1rem;
    font-size: 1.25rem;
  }
  .navbar-expand-md .mega-menu-panel > .container > .navbar-nav .navbar-nav {
    position: static;
    display: block !important;
    flex-direction: column;
    align-items: flex-start;
  }
  .navbar-expand-md .mega-menu-panel > .container > .navbar-nav .navbar-nav .nav-link {
    padding: 0;
    line-height: 1.25rem;
    outline-offset: 1px;
  }
  .navbar-expand-md .mega-menu-panel > .container > .navbar-nav .nav-item:first-child,
  .navbar-expand-md .mega-menu-panel > .container > .navbar-nav .nav-item + .nav-item {
    margin-left: 0;
  }
  .navbar-expand-md .mega-menu-panel > .container > .navbar-nav .nav-link.back {
    display: none;
  }
}

@media (max-width: 979.98px) {
  .navbar-expand-lg .mega-menu .nav-link, .navbar-expand-lg.minimized .mega-menu .nav-link {
    padding-right: 0;
    outline-offset: -1px;
  }
  .navbar-expand-lg .mega-menu .nav-link[data-toggle="collapse"]::before, .navbar-expand-lg.minimized .mega-menu .nav-link[data-toggle="collapse"]::before {
    margin-top: .3125rem;
    margin-right: .9375rem;
    content: "";
    border-top: .4375em solid;
    border-right: .4375em solid transparent;
    border-left: .4375em solid transparent;
    transform: rotateZ(180deg);
  }
  .navbar-expand-lg .mega-menu .nav-link[data-toggle="collapse"].collapsed::before, .navbar-expand-lg.minimized .mega-menu .nav-link[data-toggle="collapse"].collapsed::before {
    transform: rotateZ(0deg);
  }
  .navbar-expand-lg .mega-menu-panel .nav-link.back, .navbar-expand-lg.minimized .mega-menu-panel .nav-link.back {
    display: block;
  }
  .navbar-expand-lg .mega-menu-panel .nav-link.back::before, .navbar-expand-lg.minimized .mega-menu-panel .nav-link.back::before {
    float: left;
    transform: rotateZ(180deg);
  }
  .navbar-expand-lg .mega-menu-panel > .container, .navbar-expand-lg.minimized .mega-menu-panel > .container {
    width: 100%;
    padding: 0;
    margin: 0;
  }
  .navbar-expand-lg .mega-menu-panel > .container a[data-toggle="collapse"], .navbar-expand-lg.minimized .mega-menu-panel > .container a[data-toggle="collapse"] {
    display: none;
  }
  .navbar-expand-lg .mega-menu-panel > .container > .navbar-nav > .nav-item, .navbar-expand-lg.minimized .mega-menu-panel > .container > .navbar-nav > .nav-item {
    padding: 0;
    border: 0;
  }
  .navbar-expand-lg .mega-menu-panel > .container > .navbar-nav .navbar-nav .nav-item:last-child, .navbar-expand-lg.minimized .mega-menu-panel > .container > .navbar-nav .navbar-nav .nav-item:last-child {
    border-bottom: 1px solid #333;
  }
}

@media (min-width: 980px) {
  .navbar-expand-lg .mega-menu {
    overflow: visible;
  }
  .navbar-expand-lg .mega-menu > .navbar-nav {
    transition: none;
    transform: none;
  }
  .navbar-expand-lg .mega-menu > .navbar-nav .nav-link::before {
    display: none;
  }
  .navbar-expand-lg .mega-menu-panel {
    position: absolute;
    top: 100px;
    left: 0;
    z-index: 1030;
    width: 100%;
    padding: 1rem 0;
    background-color: #000;
    border-top: 1px solid #333;
  }
  .navbar-expand-lg .mega-menu-panel a[data-toggle="collapse"] {
    display: block;
    float: right;
    margin: 1.25rem;
    color: #fff;
  }
  .navbar-expand-lg .mega-menu-panel .nav-link {
    margin-left: 0;
  }
  .navbar-expand-lg .mega-menu-panel .svg-delete {
    width: 1.25rem;
    height: 1.25rem;
  }
  .navbar-expand-lg .mega-menu-panel > .container > .navbar-nav {
    flex-direction: row;
    align-items: flex-start;
  }
  .navbar-expand-lg .mega-menu-panel > .container > .navbar-nav > .nav-item {
    flex-grow: 1;
    padding: 1rem 0;
  }
  .navbar-expand-lg .mega-menu-panel > .container > .navbar-nav > .nav-item > .nav-link {
    padding: 0;
    margin-right: .625rem;
    margin-bottom: 1rem;
    font-size: 1.25rem;
  }
  .navbar-expand-lg .mega-menu-panel > .container > .navbar-nav .navbar-nav {
    position: static;
    display: block !important;
    flex-direction: column;
    align-items: flex-start;
  }
  .navbar-expand-lg .mega-menu-panel > .container > .navbar-nav .navbar-nav .nav-link {
    padding: 0;
    line-height: 1.25rem;
    outline-offset: 1px;
  }
  .navbar-expand-lg .mega-menu-panel > .container > .navbar-nav .nav-item:first-child,
  .navbar-expand-lg .mega-menu-panel > .container > .navbar-nav .nav-item + .nav-item {
    margin-left: 0;
  }
  .navbar-expand-lg .mega-menu-panel > .container > .navbar-nav .nav-link.back {
    display: none;
  }
}

@media (max-width: 1219.98px) {
  .navbar-expand-xl .mega-menu .nav-link, .navbar-expand-xl.minimized .mega-menu .nav-link {
    padding-right: 0;
    outline-offset: -1px;
  }
  .navbar-expand-xl .mega-menu .nav-link[data-toggle="collapse"]::before, .navbar-expand-xl.minimized .mega-menu .nav-link[data-toggle="collapse"]::before {
    margin-top: .3125rem;
    margin-right: .9375rem;
    content: "";
    border-top: .4375em solid;
    border-right: .4375em solid transparent;
    border-left: .4375em solid transparent;
    transform: rotateZ(180deg);
  }
  .navbar-expand-xl .mega-menu .nav-link[data-toggle="collapse"].collapsed::before, .navbar-expand-xl.minimized .mega-menu .nav-link[data-toggle="collapse"].collapsed::before {
    transform: rotateZ(0deg);
  }
  .navbar-expand-xl .mega-menu-panel .nav-link.back, .navbar-expand-xl.minimized .mega-menu-panel .nav-link.back {
    display: block;
  }
  .navbar-expand-xl .mega-menu-panel .nav-link.back::before, .navbar-expand-xl.minimized .mega-menu-panel .nav-link.back::before {
    float: left;
    transform: rotateZ(180deg);
  }
  .navbar-expand-xl .mega-menu-panel > .container, .navbar-expand-xl.minimized .mega-menu-panel > .container {
    width: 100%;
    padding: 0;
    margin: 0;
  }
  .navbar-expand-xl .mega-menu-panel > .container a[data-toggle="collapse"], .navbar-expand-xl.minimized .mega-menu-panel > .container a[data-toggle="collapse"] {
    display: none;
  }
  .navbar-expand-xl .mega-menu-panel > .container > .navbar-nav > .nav-item, .navbar-expand-xl.minimized .mega-menu-panel > .container > .navbar-nav > .nav-item {
    padding: 0;
    border: 0;
  }
  .navbar-expand-xl .mega-menu-panel > .container > .navbar-nav .navbar-nav .nav-item:last-child, .navbar-expand-xl.minimized .mega-menu-panel > .container > .navbar-nav .navbar-nav .nav-item:last-child {
    border-bottom: 1px solid #333;
  }
}

@media (min-width: 1220px) {
  .navbar-expand-xl .mega-menu {
    overflow: visible;
  }
  .navbar-expand-xl .mega-menu > .navbar-nav {
    transition: none;
    transform: none;
  }
  .navbar-expand-xl .mega-menu > .navbar-nav .nav-link::before {
    display: none;
  }
  .navbar-expand-xl .mega-menu-panel {
    position: absolute;
    top: 100px;
    left: 0;
    z-index: 1030;
    width: 100%;
    padding: 1rem 0;
    background-color: #000;
    border-top: 1px solid #333;
  }
  .navbar-expand-xl .mega-menu-panel a[data-toggle="collapse"] {
    display: block;
    float: right;
    margin: 1.25rem;
    color: #fff;
  }
  .navbar-expand-xl .mega-menu-panel .nav-link {
    margin-left: 0;
  }
  .navbar-expand-xl .mega-menu-panel .svg-delete {
    width: 1.25rem;
    height: 1.25rem;
  }
  .navbar-expand-xl .mega-menu-panel > .container > .navbar-nav {
    flex-direction: row;
    align-items: flex-start;
  }
  .navbar-expand-xl .mega-menu-panel > .container > .navbar-nav > .nav-item {
    flex-grow: 1;
    padding: 1rem 0;
  }
  .navbar-expand-xl .mega-menu-panel > .container > .navbar-nav > .nav-item > .nav-link {
    padding: 0;
    margin-right: .625rem;
    margin-bottom: 1rem;
    font-size: 1.25rem;
  }
  .navbar-expand-xl .mega-menu-panel > .container > .navbar-nav .navbar-nav {
    position: static;
    display: block !important;
    flex-direction: column;
    align-items: flex-start;
  }
  .navbar-expand-xl .mega-menu-panel > .container > .navbar-nav .navbar-nav .nav-link {
    padding: 0;
    line-height: 1.25rem;
    outline-offset: 1px;
  }
  .navbar-expand-xl .mega-menu-panel > .container > .navbar-nav .nav-item:first-child,
  .navbar-expand-xl .mega-menu-panel > .container > .navbar-nav .nav-item + .nav-item {
    margin-left: 0;
  }
  .navbar-expand-xl .mega-menu-panel > .container > .navbar-nav .nav-link.back {
    display: none;
  }
}

@media (max-width: 1379.98px) {
  .navbar-expand-xxl .mega-menu .nav-link, .navbar-expand-xxl.minimized .mega-menu .nav-link {
    padding-right: 0;
    outline-offset: -1px;
  }
  .navbar-expand-xxl .mega-menu .nav-link[data-toggle="collapse"]::before, .navbar-expand-xxl.minimized .mega-menu .nav-link[data-toggle="collapse"]::before {
    margin-top: .3125rem;
    margin-right: .9375rem;
    content: "";
    border-top: .4375em solid;
    border-right: .4375em solid transparent;
    border-left: .4375em solid transparent;
    transform: rotateZ(180deg);
  }
  .navbar-expand-xxl .mega-menu .nav-link[data-toggle="collapse"].collapsed::before, .navbar-expand-xxl.minimized .mega-menu .nav-link[data-toggle="collapse"].collapsed::before {
    transform: rotateZ(0deg);
  }
  .navbar-expand-xxl .mega-menu-panel .nav-link.back, .navbar-expand-xxl.minimized .mega-menu-panel .nav-link.back {
    display: block;
  }
  .navbar-expand-xxl .mega-menu-panel .nav-link.back::before, .navbar-expand-xxl.minimized .mega-menu-panel .nav-link.back::before {
    float: left;
    transform: rotateZ(180deg);
  }
  .navbar-expand-xxl .mega-menu-panel > .container, .navbar-expand-xxl.minimized .mega-menu-panel > .container {
    width: 100%;
    padding: 0;
    margin: 0;
  }
  .navbar-expand-xxl .mega-menu-panel > .container a[data-toggle="collapse"], .navbar-expand-xxl.minimized .mega-menu-panel > .container a[data-toggle="collapse"] {
    display: none;
  }
  .navbar-expand-xxl .mega-menu-panel > .container > .navbar-nav > .nav-item, .navbar-expand-xxl.minimized .mega-menu-panel > .container > .navbar-nav > .nav-item {
    padding: 0;
    border: 0;
  }
  .navbar-expand-xxl .mega-menu-panel > .container > .navbar-nav .navbar-nav .nav-item:last-child, .navbar-expand-xxl.minimized .mega-menu-panel > .container > .navbar-nav .navbar-nav .nav-item:last-child {
    border-bottom: 1px solid #333;
  }
}

@media (min-width: 1380px) {
  .navbar-expand-xxl .mega-menu {
    overflow: visible;
  }
  .navbar-expand-xxl .mega-menu > .navbar-nav {
    transition: none;
    transform: none;
  }
  .navbar-expand-xxl .mega-menu > .navbar-nav .nav-link::before {
    display: none;
  }
  .navbar-expand-xxl .mega-menu-panel {
    position: absolute;
    top: 100px;
    left: 0;
    z-index: 1030;
    width: 100%;
    padding: 1rem 0;
    background-color: #000;
    border-top: 1px solid #333;
  }
  .navbar-expand-xxl .mega-menu-panel a[data-toggle="collapse"] {
    display: block;
    float: right;
    margin: 1.25rem;
    color: #fff;
  }
  .navbar-expand-xxl .mega-menu-panel .nav-link {
    margin-left: 0;
  }
  .navbar-expand-xxl .mega-menu-panel .svg-delete {
    width: 1.25rem;
    height: 1.25rem;
  }
  .navbar-expand-xxl .mega-menu-panel > .container > .navbar-nav {
    flex-direction: row;
    align-items: flex-start;
  }
  .navbar-expand-xxl .mega-menu-panel > .container > .navbar-nav > .nav-item {
    flex-grow: 1;
    padding: 1rem 0;
  }
  .navbar-expand-xxl .mega-menu-panel > .container > .navbar-nav > .nav-item > .nav-link {
    padding: 0;
    margin-right: .625rem;
    margin-bottom: 1rem;
    font-size: 1.25rem;
  }
  .navbar-expand-xxl .mega-menu-panel > .container > .navbar-nav .navbar-nav {
    position: static;
    display: block !important;
    flex-direction: column;
    align-items: flex-start;
  }
  .navbar-expand-xxl .mega-menu-panel > .container > .navbar-nav .navbar-nav .nav-link {
    padding: 0;
    line-height: 1.25rem;
    outline-offset: 1px;
  }
  .navbar-expand-xxl .mega-menu-panel > .container > .navbar-nav .nav-item:first-child,
  .navbar-expand-xxl .mega-menu-panel > .container > .navbar-nav .nav-item + .nav-item {
    margin-left: 0;
  }
  .navbar-expand-xxl .mega-menu-panel > .container > .navbar-nav .nav-link.back {
    display: none;
  }
}

.navbar-expand .mega-menu .nav-link, .navbar-expand.minimized .mega-menu .nav-link {
  padding-right: 0;
  outline-offset: -1px;
}

.navbar-expand .mega-menu .nav-link[data-toggle="collapse"]::before, .navbar-expand.minimized .mega-menu .nav-link[data-toggle="collapse"]::before {
  margin-top: .3125rem;
  margin-right: .9375rem;
  content: "";
  border-top: .4375em solid;
  border-right: .4375em solid transparent;
  border-left: .4375em solid transparent;
  transform: rotateZ(180deg);
}

.navbar-expand .mega-menu .nav-link[data-toggle="collapse"].collapsed::before, .navbar-expand.minimized .mega-menu .nav-link[data-toggle="collapse"].collapsed::before {
  transform: rotateZ(0deg);
}

.navbar-expand .mega-menu-panel .nav-link.back, .navbar-expand.minimized .mega-menu-panel .nav-link.back {
  display: block;
}

.navbar-expand .mega-menu-panel .nav-link.back::before, .navbar-expand.minimized .mega-menu-panel .nav-link.back::before {
  float: left;
  transform: rotateZ(180deg);
}

.navbar-expand .mega-menu-panel > .container, .navbar-expand.minimized .mega-menu-panel > .container {
  width: 100%;
  padding: 0;
  margin: 0;
}

.navbar-expand .mega-menu-panel > .container a[data-toggle="collapse"], .navbar-expand.minimized .mega-menu-panel > .container a[data-toggle="collapse"] {
  display: none;
}

.navbar-expand .mega-menu-panel > .container > .navbar-nav > .nav-item, .navbar-expand.minimized .mega-menu-panel > .container > .navbar-nav > .nav-item {
  padding: 0;
  border: 0;
}

.navbar-expand .mega-menu-panel > .container > .navbar-nav .navbar-nav .nav-item:last-child, .navbar-expand.minimized .mega-menu-panel > .container > .navbar-nav .navbar-nav .nav-item:last-child {
  border-bottom: 1px solid #333;
}

.navbar-expand .mega-menu {
  overflow: visible;
}

.navbar-expand .mega-menu > .navbar-nav {
  transition: none;
  transform: none;
}

.navbar-expand .mega-menu > .navbar-nav .nav-link::before {
  display: none;
}

.navbar-expand .mega-menu-panel {
  position: absolute;
  top: 100px;
  left: 0;
  z-index: 1030;
  width: 100%;
  padding: 1rem 0;
  background-color: #000;
  border-top: 1px solid #333;
}

.navbar-expand .mega-menu-panel a[data-toggle="collapse"] {
  display: block;
  float: right;
  margin: 1.25rem;
  color: #fff;
}

.navbar-expand .mega-menu-panel .nav-link {
  margin-left: 0;
}

.navbar-expand .mega-menu-panel .svg-delete {
  width: 1.25rem;
  height: 1.25rem;
}

.navbar-expand .mega-menu-panel > .container > .navbar-nav {
  flex-direction: row;
  align-items: flex-start;
}

.navbar-expand .mega-menu-panel > .container > .navbar-nav > .nav-item {
  flex-grow: 1;
  padding: 1rem 0;
}

.navbar-expand .mega-menu-panel > .container > .navbar-nav > .nav-item > .nav-link {
  padding: 0;
  margin-right: .625rem;
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.navbar-expand .mega-menu-panel > .container > .navbar-nav .navbar-nav {
  position: static;
  display: block !important;
  flex-direction: column;
  align-items: flex-start;
}

.navbar-expand .mega-menu-panel > .container > .navbar-nav .navbar-nav .nav-link {
  padding: 0;
  line-height: 1.25rem;
  outline-offset: 1px;
}

.navbar-expand .mega-menu-panel > .container > .navbar-nav .nav-item:first-child,
.navbar-expand .mega-menu-panel > .container > .navbar-nav .nav-item + .nav-item {
  margin-left: 0;
}

.navbar-expand .mega-menu-panel > .container > .navbar-nav .nav-link.back {
  display: none;
}

@media (max-width: 767.98px) {
  .navbar-brand,
  .navbar-brand + .navbar-brand {
    align-self: center;
  }
}

.table > thead > tr,
.table > tbody > tr,
.table > tfoot > tr {
  border-bottom: 1px solid #ccc;
}

.table > thead > tr > th,
.table > thead > tr > td,
.table > tbody > tr > th,
.table > tbody > tr > td,
.table > tfoot > tr > th,
.table > tfoot > tr > td {
  font-size: 0.875rem;
  line-height: 1.25rem;
  vertical-align: middle;
  border: 0;
}

.table > thead > tr > th:first-child,
.table > thead > tr > td:first-child,
.table > tbody > tr > th:first-child,
.table > tbody > tr > td:first-child,
.table > tfoot > tr > th:first-child,
.table > tfoot > tr > td:first-child {
  padding-left: .625rem;
}

.table > thead > tr [class^="icon-"],
.table > thead > tr [class*=" icon-"],
.table > tbody > tr [class^="icon-"],
.table > tbody > tr [class*=" icon-"],
.table > tfoot > tr [class^="icon-"],
.table > tfoot > tr [class*=" icon-"] {
  font-size: 1.8125rem;
}

.table > thead > tr [class^="svg-"],
.table > tbody > tr [class^="svg-"],
.table > tfoot > tr [class^="svg-"] {
  display: block;
  width: 1.8125rem;
  height: 1.8125rem;
  margin: 0 auto;
}

.table > thead > tr [class^="svg-"]::after,
.table > tbody > tr [class^="svg-"]::after,
.table > tfoot > tr [class^="svg-"]::after {
  width: 1.8125rem;
  height: 1.8125rem;
}

.table thead > tr {
  border-bottom-width: 2px;
}

.table thead > tr > th {
  font-size: 1rem;
  font-weight: 700;
}

.table tbody > th {
  font-weight: 400;
}

.table.has-checkbox tr.selected > td,
.table.has-checkbox tr.selected > th {
  background-color: #ddd;
}

.table.has-checkbox tr > th:first-child,
.table.has-checkbox tr > td:first-child {
  width: 1.25rem;
  padding-top: 0;
  padding-bottom: 0;
}

.table.has-checkbox tr > th:first-child .custom-control.custom-checkbox,
.table.has-checkbox tr > td:first-child .custom-control.custom-checkbox {
  padding-top: 0;
  padding-left: 1.25rem;
  margin: 0;
  vertical-align: middle;
}

.table.has-icon tr > td,
.table.has-icon tr > th {
  height: 3.1875rem;
  padding-top: .6875rem;
  vertical-align: middle;
}

.table.has-icon tr > td span,
.table.has-icon tr > td img,
.table.has-icon tr > th span,
.table.has-icon tr > th img {
  vertical-align: middle;
}

.table.has-icon tr > td img,
.table.has-icon tr > th img {
  width: 1.875rem;
  height: 1.875rem;
  margin-right: .625rem;
}

.table.has-icon tr > td [class^="icon-"],
.table.has-icon tr > th [class^="icon-"] {
  width: 1.875rem;
  margin-right: .375rem;
  font-size: 1.8125rem;
  line-height: 0;
  vertical-align: middle;
}

.table.table-reflow thead > tr,
.table.table-reflow tbody > tr {
  border: 0;
}

.table.table-reflow thead > tr > td,
.table.table-reflow tbody > tr > td {
  line-height: 1.25rem;
}

.table.table-reflow thead > tr > th:first-child,
.table.table-reflow tbody > tr > th:first-child {
  border-bottom: 2px solid #ccc;
}

.table.table-reflow thead > tr > th,
.table.table-reflow thead > tr > td,
.table.table-reflow tbody > tr > th,
.table.table-reflow tbody > tr > td {
  border-bottom: 1px solid #ccc;
}

.table.table-reflow thead > tr > td:last-child,
.table.table-reflow tbody > tr > td:last-child {
  border-right: 0;
}

.table.table-reflow thead > tr {
  border: 0;
}

.table.table-reflow thead > tr > th:not(:first-child) {
  padding-left: .625rem;
  border-bottom: 1px solid #ccc;
}

.table.table-reflow thead > tr > th:last-child {
  border-right: 0;
}

.table.table-reflow tbody > tr > th:first-child {
  padding-left: 0;
}

.table > thead > tr > td.success,
.table > thead > tr > th.success,
.table > thead > tr.success > td,
.table > thead > tr.success > th,
.table > tbody > tr > td.success,
.table > tbody > tr > th.success,
.table > tbody > tr.success > td,
.table > tbody > tr.success > th,
.table > tfoot > tr > td.success,
.table > tfoot > tr > th.success,
.table > tfoot > tr.success > td,
.table > tfoot > tr.success > th {
  background-color: #32c832;
}

.table-hover > tbody > tr > td.success:hover,
.table-hover > tbody > tr > th.success:hover,
.table-hover > tbody > tr.success:hover > td,
.table-hover > tbody > tr:hover > .success,
.table-hover > tbody > tr.success:hover > th {
  background-color: #2db42d;
}

.table > thead > tr > td.info,
.table > thead > tr > th.info,
.table > thead > tr.info > td,
.table > thead > tr.info > th,
.table > tbody > tr > td.info,
.table > tbody > tr > th.info,
.table > tbody > tr.info > td,
.table > tbody > tr.info > th,
.table > tfoot > tr > td.info,
.table > tfoot > tr > th.info,
.table > tfoot > tr.info > td,
.table > tfoot > tr.info > th {
  background-color: #527edb;
}

.table-hover > tbody > tr > td.info:hover,
.table-hover > tbody > tr > th.info:hover,
.table-hover > tbody > tr.info:hover > td,
.table-hover > tbody > tr:hover > .info,
.table-hover > tbody > tr.info:hover > th {
  background-color: #3d6ed7;
}

.table > thead > tr > td.warning,
.table > thead > tr > th.warning,
.table > thead > tr.warning > td,
.table > thead > tr.warning > th,
.table > tbody > tr > td.warning,
.table > tbody > tr > th.warning,
.table > tbody > tr.warning > td,
.table > tbody > tr.warning > th,
.table > tfoot > tr > td.warning,
.table > tfoot > tr > th.warning,
.table > tfoot > tr.warning > td,
.table > tfoot > tr.warning > th {
  background-color: #fc0;
}

.table-hover > tbody > tr > td.warning:hover,
.table-hover > tbody > tr > th.warning:hover,
.table-hover > tbody > tr.warning:hover > td,
.table-hover > tbody > tr:hover > .warning,
.table-hover > tbody > tr.warning:hover > th {
  background-color: #e6b800;
}

.table > thead > tr > td.danger,
.table > thead > tr > th.danger,
.table > thead > tr.danger > td,
.table > thead > tr.danger > th,
.table > tbody > tr > td.danger,
.table > tbody > tr > th.danger,
.table > tbody > tr.danger > td,
.table > tbody > tr.danger > th,
.table > tfoot > tr > td.danger,
.table > tfoot > tr > th.danger,
.table > tfoot > tr.danger > td,
.table > tfoot > tr.danger > th {
  background-color: #cd3c14;
}

.table-hover > tbody > tr > td.danger:hover,
.table-hover > tbody > tr > th.danger:hover,
.table-hover > tbody > tr.danger:hover > td,
.table-hover > tbody > tr:hover > .danger,
.table-hover > tbody > tr.danger:hover > th {
  background-color: #b63512;
}

caption {
  padding: .75rem 0;
  font-size: 2.125rem;
  font-weight: 700;
  color: #000;
  caption-side: top;
  background-color: #fff;
}

.tablesorter caption {
  padding: .75rem 0;
  font-size: 2.125rem;
  font-weight: 700;
  color: #000;
  background-color: #fff;
}

.tablesorter th {
  padding-right: 30px;
  text-align: left;
  cursor: pointer;
}

.tablesorter th.header {
  position: relative;
}

.tablesorter th .tablesorter-header-inner span {
  padding-right: 1.6875rem;
}

.tablesorter th.tablesorter-headerDesc .tablesorter-header-inner span::after {
  position: absolute;
  top: 45%;
  width: .875rem;
  height: .4375rem;
  margin-left: .625rem;
  content: "";
  background-image: url("data:image/svg+xml;charset=utf8, %3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2014%207%22%3E%3Cpath%20d%3D%22M7%207L0%200h14L7%207z%22%2F%3E%3C%2Fsvg%3E");
  transform: rotate(180deg);
}

.tablesorter th.tablesorter-headerAsc .tablesorter-header-inner span::after {
  position: absolute;
  top: 45%;
  width: .875rem;
  height: .4375rem;
  margin-left: .625rem;
  content: "";
  background-image: url("data:image/svg+xml;charset=utf8, %3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2014%207%22%3E%3Cpath%20d%3D%22M7%207L0%200h14L7%207z%22%2F%3E%3C%2Fsvg%3E");
}

.tablesorter th.tablesorter-headerUnSorted .tablesorter-header-inner:hover span::after,
.tablesorter th.tablesorter-headerUnSorted .tablesorter-header-inner:focus span::after {
  position: absolute;
  top: 31%;
  width: .875rem;
  height: 1.0625rem;
  margin-left: .625rem;
  content: "";
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2014%2017%22%3E%3Cpath%20d%3D%22M%207%2C17%200%2C10%20h%2014%20l%20-7%2C7%20z%22%2F%3E%3Cpath%20d%3D%22M%207%2C0%2014%2C7%200%2C7%207%2C0%20z%22%2F%3E%3C%2Fsvg%3E");
}

.tablesorter th a {
  color: #000;
  text-decoration: none;
}

.tablesorter th a:hover, .tablesorter th a:focus {
  color: #f16e00;
  text-decoration: underline;
}

.tablesorter td {
  text-align: left;
}

.modal .modal-content {
  display: flex;
  flex-direction: column;
  padding: 2.5rem;
  border: 0;
}

.modal .modal-header {
  position: relative;
  min-height: 1.25rem;
  padding: 0;
  margin-bottom: 2.5rem;
  border: 0;
}

.modal .modal-header .btn {
  position: absolute;
  top: -.625rem;
  right: -.625rem;
  padding: .625rem;
  background-color: transparent;
  border: 0;
}

.modal .modal-header .btn .close {
  width: 1.25rem;
  height: 1.25rem;
  margin: 0;
  background-image: url("data:image/svg+xml;charset=utf8, %3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22852.063%22%20height%3D%22852.063%22%20viewBox%3D%220%200%20852.063%20852.063%22%3E%3Cpath%20id%3D%22closing_cross.svg%22%20data-name%3D%22closing%20cross.svg%22%20class%3D%22cls-1%22%20d%3D%22M.987%2057.125L57.125.987%20851.048%20794.91l-56.138%2056.138zM794.91.987l56.138%2056.138L57.125%20851.048.987%20794.91z%22%20fill%3D%22%23666%22%2F%3E%3C%2Fsvg%3E");
  background-size: 1.25rem;
  opacity: 1;
}

.modal .modal-body {
  display: flex;
  flex-direction: column;
  padding: 0;
  border: 0;
}

.modal .modal-body .card,
.modal .modal-body .card {
  display: flex;
  flex-direction: row;
  padding: 0;
  border: 0;
}

.modal .modal-body .card-body {
  padding: 0;
}

.modal .modal-body .card-icon {
  margin-right: 1.25rem;
}

.modal .modal-body [class^="svg"],
.modal .modal-body [class^="svg"]::after {
  width: 2.875rem;
  height: 2.875rem;
}

.modal .modal-body [class^="svg"] {
  display: block;
}

.modal .modal-body [class^="svg"]::after {
  top: auto;
}

.modal .modal-body .card-title {
  margin-bottom: 1.25rem;
  font-size: 1.5rem;
}

.modal .modal-footer {
  align-self: flex-end;
  padding: 0;
  margin-top: 2.5rem;
  border: 0;
}

.modal .modal-footer .btn + .btn {
  margin-left: 1.25rem;
}

@media (max-width: 479.98px) {
  .modal .modal-content {
    padding: 1.25rem;
  }
  .modal .modal-header {
    margin: 0;
  }
  .modal .modal-body .card {
    flex-direction: column;
  }
  .modal .modal-body .card-icon {
    align-self: center;
    margin-right: 0;
  }
  .modal .modal-body .card-icon,
  .modal .modal-body [class^="svg"]::after {
    width: 4rem;
    height: 4rem;
  }
  .modal .modal-body .card-title {
    font-size: 1rem;
  }
  .modal .modal-body .card-body {
    margin-top: 1.25rem;
  }
  .modal .modal-footer {
    align-self: center;
  }
}

.nav .nav-item:first-child {
  margin-left: -0.625rem;
}

.nav.flex-column .nav-item:first-child {
  margin-left: 0;
}

.nav .nav-link {
  padding: 0.625rem 0.625rem;
  line-height: 1.25rem;
}

.nav-tabs .nav-item:first-child {
  margin-left: 0;
}

.nav-tabs .nav-item + .nav-item {
  margin-left: .5rem;
}

@media (max-width: 979.98px) {
  .nav-tabs .nav-item + .nav-item {
    margin-left: 0;
  }
}

.nav-tabs .nav-link:not(.disabled):focus, .nav-tabs .nav-link:not(.disabled).dropdown-toggle:focus {
  outline-width: 1px;
  outline-style: dotted;
  outline-offset: -6px;
}

.nav-tabs .nav-link:not(.disabled) + .nav-link {
  margin-left: .5rem;
}

@media (max-width: 979.98px) {
  .nav-tabs .nav-link:not(.disabled) + .nav-link {
    margin-left: 0;
  }
}

.o-tab-container {
  display: flex;
  flex-flow: row wrap;
}

.o-tab-container .o-tab-heading {
  position: relative;
  display: inline-block;
  padding: .625rem 1.25rem;
  margin-left: .5rem;
  font-weight: 700;
  color: #000;
  text-align: center;
  background-color: #fff;
  border: 0;
  border-bottom: 2px solid #000;
}

.o-tab-container .o-tab-heading:first-child {
  margin-left: 0;
  border-top: 0;
}

.o-tab-container .o-tab-heading[aria-expanded="false"] {
  border-bottom: 2px solid #000;
}

.o-tab-container .o-tab-heading[aria-expanded="true"] {
  padding-top: .5rem;
  border: 2px solid #000;
  border-bottom: 0;
}

.o-tab-container .o-tab-heading:focus {
  outline-offset: -4px;
}

.o-tab-container .o-tab-heading:hover:not([aria-expanded="true"]) {
  color: #fff;
  background-color: #000;
}

.o-tab-container .o-tab-content {
  display: none;
  align-self: flex-end;
  order: 1;
  width: 100%;
  padding: .625rem 1.25rem;
  background-color: #fff;
  box-shadow: 0 -2px 0 #000;
}

.o-tab-container .o-tab-content.show {
  display: block;
}

@media (max-width: 479.98px) {
  .o-tab-container .o-tab-heading {
    flex-grow: 1;
    margin-left: 0;
    text-align: center;
  }
  .o-tab-container .o-tab-heading[aria-expanded="false"] {
    color: #fff;
    background-color: #666;
  }
  .o-tab-container .o-tab-heading[aria-expanded="false"]:active {
    color: #fff;
    background-color: #f16e00;
  }
  .o-tab-container.accordion-layout {
    display: block;
  }
  .o-tab-container.accordion-layout .o-tab-heading {
    position: relative;
    display: block;
    padding: .9375rem 0;
    margin-left: 0;
    font-weight: 700;
    color: #000;
    text-align: left;
    background-color: #fff;
    border: 0;
  }
  .o-tab-container.accordion-layout .o-tab-heading:focus {
    outline-offset: 0;
  }
  .o-tab-container.accordion-layout .o-tab-heading:hover {
    color: #f16e00;
    background-color: #fff;
  }
  .o-tab-container.accordion-layout .o-tab-heading[aria-expanded="false"] {
    border-bottom: 1px #ccc solid;
  }
  .o-tab-container.accordion-layout .o-tab-heading[aria-expanded="true"]::after {
    transform: rotate(180deg);
  }
  .o-tab-container.accordion-layout .o-tab-heading:first-child {
    margin-left: 0;
    border-top: 1px solid #ccc;
  }
  .o-tab-container.accordion-layout .o-tab-heading::after {
    position: absolute;
    top: 1.375rem;
    right: .625rem;
    width: .875rem;
    height: .4375rem;
    content: "";
    background-image: url("data:image/svg+xml;charset=utf8, %3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2014%207%22%3E%3Cpath%20d%3D%22M7%207L0%200h14L7%207z%22%2F%3E%3C%2Fsvg%3E");
  }
  .o-tab-container.accordion-layout .o-tab-content {
    padding-left: 0;
    border-bottom: 1px #ccc solid;
    box-shadow: none;
  }
}

.nav-pills.nav-stacked .nav-item {
  margin: 0;
}

.nav-pills.nav-stacked .nav-item + .nav-item {
  margin-left: 0;
}

.nav-pills .nav-item:first-child {
  margin-left: 0;
}

.nav-pills .nav-item + .nav-item {
  margin-left: 5px;
}

.nav-pills .nav-link {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.nav-pills .nav-link:active {
  background-color: #000;
}

.nav-pills .nav-link:hover {
  color: #fff;
  cursor: default;
  background-color: #000;
}

.nav-pills .nav-link:hover.disabled {
  color: #666;
  background-color: transparent;
}

.nav-pills .nav-link:focus {
  color: #fff;
  background-color: #f16e00;
  outline: none;
}

.nav-pills .nav-link:focus.disabled {
  color: #666;
  background-color: transparent;
}

.nav-pills .dropdown-menu {
  right: 0;
  left: auto;
}

.nav-pills .dropdown-menu .nav-item {
  margin: 0;
}

.nav-pills .dropdown-menu .nav-item .nav-link {
  display: block;
  padding: .625rem .625rem .625rem 1.25rem;
  margin: 0;
  color: #000;
  background: #fff;
  border: 0;
}

.nav-pills .dropdown-menu .nav-item .nav-link:focus {
  outline: 1px dotted #000;
  outline-offset: -2px;
}

.nav-pills .dropdown-menu .nav-item .nav-link:hover {
  color: #fff;
  background: #000;
}

.tab-content {
  padding: 1.125rem;
  border-color: #000;
  border-style: solid;
  border-width: 0 2px 2px;
}

.tab-content .tab-pane p {
  margin-bottom: 0;
}

.tab-content-clear {
  border: 0;
}

.breadcrum {
  line-height: 1;
}

.overflow-nav {
  display: none;
}

.o-nav-local.resizing {
  overflow: hidden;
}

.hide {
  display: none !important;
}

.show-inline-block {
  display: inline-block !important;
}

.overflow-nav-list .nav-link {
  border: 0;
}

.overflow-nav-list .nav-link:focus {
  outline-offset: -2px;
}

.overflow-nav-list .nav-item:last-child {
  margin-bottom: 0;
}

.o-nav-local .overflow-nav-list {
  margin-top: 1px;
  border: 0;
  box-shadow: 0 .3125rem .625rem -.375rem #999;
}

.o-nav-local {
  margin-bottom: 2.5rem;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

.o-nav-local > .container,
.o-nav-local > .container-fluid {
  display: flex;
}

.o-nav-local ul + ul {
  flex-shrink: 0;
  margin-left: 1rem;
}

.o-nav-local .nav-item + .nav-item {
  margin-left: 1rem;
}

.o-nav-local .nav.flex-column .nav-item {
  margin-left: 0;
}

.o-nav-local .nav .nav-link,
.o-nav-local .nav .dropdown-toggle {
  display: inline-block;
  padding: 1rem 0.625rem 1.125rem;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1;
}

.o-nav-local .nav .nav-link:focus,
.o-nav-local .nav .dropdown-toggle:focus {
  outline: 1px dotted;
  outline-offset: -1px;
}

.o-nav-local .nav-link.active {
  color: #f16e00;
}

.o-nav-local .dropdown-toggle.show {
  color: #000;
}

.o-nav-local .dropdown-menu .nav-item,
.o-nav-local .dropdown-menu .nav-item + .nav-item {
  margin: 0;
}

.o-nav-local .dropdown-menu .nav-link {
  display: block;
  padding: .625rem .625rem .625rem 1.25rem;
}

.o-nav-local .dropdown-menu .nav-link:focus {
  outline-offset: -2px;
}

.o-nav-local .dropdown-menu .nav-link:hover {
  color: #fff;
  background: #000;
}

.o-nav-local.inverse {
  background-color: #000;
  border-top-color: #333;
  border-bottom-color: #333;
}

.o-nav-local.inverse .nav-link {
  color: #fff;
  background-color: #000;
}

.o-nav-local.inverse .nav-link:hover, .o-nav-local.inverse .nav-link:focus {
  color: #f16e00;
}

.o-nav-local.inverse .nav-link:focus {
  outline-color: #fff;
}

.o-nav-local.inverse .nav-link.active {
  color: #f16e00;
}

.skiplinks {
  z-index: 99999;
}

.skiplinks .skiplinks-section li {
  float: left;
}

.skiplinks .skiplinks-section a {
  display: block;
  padding: 10px;
  font-weight: 700;
  border-color: #999;
  border-style: none solid none none;
  border-width: 0 1px 0 0;
}

.skiplinks .skiplinks-section.skiplinks-show {
  position: absolute;
  top: 20px;
  left: 50%;
  z-index: 10000000;
  width: auto;
  height: auto;
  margin-left: -175px;
  overflow: hidden;
  background: none repeat scroll 0 0 #ccc;
  border: 2px solid #000;
  border-radius: 0;
  box-shadow: 2px 2px 1634px 1604px rgba(0, 0, 0, 0.3);
}

.skiplinks .skiplinks-section a:hover {
  cursor: pointer;
}

.skiplinks .skiplinks-section a:hover,
.skiplinks .skiplinks-section a:focus {
  color: #f16e00;
  background-color: #fff;
  outline: 0 none;
}

.skiplinks a,
.skiplinks ul,
.skiplinks li,
.skiplinks div {
  float: none;
  padding: 0;
  margin: 0;
  font-size: .95em;
  font-weight: 400;
  line-height: normal;
  color: #000;
  text-decoration: none;
  text-shadow: none;
  list-style: none;
  background: none repeat scroll 0 0 transparent;
  border: 0 none;
}

.o-footer {
  margin-top: 2.5rem;
  font-size: 0.875rem;
  font-weight: 700;
  color: #fff;
  background-color: #000;
}

.o-footer .nav-link {
  padding: 0.625rem 0.625rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.o-footer .nav-link:not(:hover), .o-footer .nav-link:not([href]) {
  color: #fff;
}

.o-footer .o-footer-top,
.o-footer .o-footer-body,
.o-footer .o-footer-bottom {
  padding-top: .625rem;
  padding-bottom: .625rem;
}

.o-footer .o-footer-top {
  border-bottom: 1px solid #333;
}

.o-footer .o-footer-body .row {
  margin-bottom: .625rem;
}

.o-footer .o-footer-body h2 {
  margin-top: .625rem;
  font-size: 0.875rem;
  color: #fff;
}

.o-footer .o-footer-body p,
.o-footer .o-footer-body p a {
  color: #fff;
}

.o-footer .o-footer-body .flex-column .nav-link {
  padding: 0;
  line-height: 1.25;
}

.o-footer .o-footer-bottom {
  border-top: 1px solid #333;
}

@media (max-width: 979.98px) {
  .o-responsive-table thead {
    display: none;
  }
  .o-responsive-table tbody,
  .o-responsive-table tr,
  .o-responsive-table th,
  .o-responsive-table td {
    display: block;
  }
}

@media (max-width: 979.98px) and (min-width: 768px) {
  .o-responsive-table tr {
    float: left;
    width: 45%;
  }
  .o-responsive-table tr:nth-child(odd) {
    margin-right: 10%;
  }
}

@media (max-width: 979.98px) {
  .o-responsive-table th,
  .o-responsive-table td {
    vertical-align: middle;
  }
  .o-responsive-table td[data-title]::before {
    float: left;
    width: 50%;
    font-weight: 700;
    content: attr(data-title);
  }
  .o-responsive-table tr {
    margin-bottom: 2em;
    border-right: 0;
    border-left: 0;
  }
  .o-responsive-table tbody th[scope="row"] {
    font-weight: 700;
    border-top: 0;
    border-bottom: 2px solid #ccc;
  }
}

.o-scroll-up {
  position: fixed;
  right: 0;
  bottom: 2.5rem;
  z-index: 1080;
  display: none;
  right: 1.25%;
  bottom: .25rem;
}

.o-scroll-up .o-scroll-up-icon {
  align-items: center;
  justify-content: center;
  float: right;
  width: 2.5rem;
  height: 2.5rem;
  color: #fff;
  text-align: center;
  text-decoration: none;
  background-color: #666;
  opacity: .8;
  transform: rotate(90deg);
}

.o-scroll-up .o-scroll-up-icon::before {
  font-family: icon-orange, sans-serif;
  font-size: 1.25rem;
  line-height: 2;
  text-align: center;
  content: "\e93f";
}

@media (min-width: 480px) {
  .o-scroll-up {
    right: 1.25%;
  }
}

@media (min-width: 768px) {
  .o-scroll-up {
    right: 1.5625%;
  }
}

@media (min-width: 980px) {
  .o-scroll-up {
    right: 3.125%;
  }
}

@media (min-width: 1220px) {
  .o-scroll-up {
    right: 3.125%;
  }
}

@media (min-width: 1380px) {
  .o-scroll-up {
    right: 4.16667%;
  }
}

@media (min-width: 480px) {
  .o-scroll-up {
    bottom: .375rem;
  }
}

@media (min-width: 768px) {
  .o-scroll-up {
    bottom: .75rem;
  }
}

@media (min-width: 980px) {
  .o-scroll-up {
    bottom: 2rem;
  }
}

@media (min-width: 1220px) {
  .o-scroll-up {
    bottom: 2.5rem;
  }
}

@media (min-width: 1380px) {
  .o-scroll-up {
    bottom: 3.75rem;
  }
}

.o-scroll-up .o-scroll-up-text {
  display: inline-block;
  padding: 0.625rem 0.625rem;
  font-weight: 700;
  background-color: #fff;
  opacity: .8;
}

.o-scroll-up.static {
  position: static;
  z-index: auto;
  display: inline;
  float: left;
}

.o-scroll-up.static .o-scroll-up-text {
  float: left;
}

.o-scroll-up.static .o-scroll-up-icon {
  position: relative;
  display: inline-block;
  float: none;
}

.o-scroll-up:hover, .o-scroll-up:focus {
  color: #f16e00;
}

.dropdown-toggle::after {
  margin-left: .625rem;
  vertical-align: 10%;
}

.dropdown-item {
  padding-left: 1.25rem;
  font-weight: 700;
}

.dropdown-item:focus {
  color: #000;
  background-color: #fff;
  outline: 1px dotted;
}

.dropdown-item:focus:hover {
  color: #fff;
  background-color: #000;
}

.carousel {
  margin-bottom: 1.875rem;
}

.carousel .carousel-item img {
  width: 100%;
}

.carousel-control-prev,
.carousel-control-next {
  top: auto;
  bottom: calc(50% - 1.875rem);
  width: 3.75rem;
  height: 3.75rem;
  text-decoration: none;
  text-shadow: none;
  background-color: transparent;
  opacity: .8;
  transition: width .5s ease;
}

.carousel-control-prev:focus,
.carousel-control-next:focus {
  outline: 1px dotted #000;
  outline-offset: -1px;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  margin-top: -1.125rem;
  font-size: 1.875rem;
  color: #000;
  text-shadow: 1px 0 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff, 0 -1px 0 #fff;
  background-image: none;
  background-repeat: no-repeat;
}

.carousel-control-prev-icon::before,
.carousel-control-next-icon::before {
  font-family: icon-orange, sans-serif;
}

.carousel-control-prev-icon::before {
  content: "\e93f";
}

.carousel-control-next-icon::before {
  content: "\e93e";
}

.carousel-indicators {
  bottom: 0;
  align-items: center;
}

.carousel-indicators:hover {
  cursor: default;
}

.carousel-indicators li {
  flex-grow: 0;
  width: .625rem;
  height: .625rem;
  margin-right: .375rem;
  background-color: #000;
  border: 1px solid #fff;
  border-radius: 50%;
}

.carousel-indicators li:hover {
  cursor: pointer;
}

.carousel-indicators li.active {
  background-color: #f16e00;
}

.swiper-button-prev,
.swiper-button-next {
  top: calc(50% - 10px);
  width: auto;
  height: auto;
  text-shadow: 1px 0 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff, 0 -1px 0 #fff;
  cursor: pointer;
  background-image: none;
  background-size: 0;
}

.swiper-button-prev::before,
.swiper-button-next::before {
  font-family: icon-orange, sans-serif;
  font-size: 1.875rem;
  content: "\e93f";
}

.swiper-button-next::before {
  content: "\e93e";
}

.swiper-pagination-bullet,
.swiper-pagination-bullet-active {
  width: .625rem;
  height: .625rem;
  border: 0;
}

.swiper-pagination-bullet {
  opacity: 1;
}

.swiper-pagination-bullet-active {
  background: #f16e00;
}

.swiper-container {
  width: 100% !important;
  margin-bottom: 30px;
  overflow: hidden;
}

.swiper-wrapper {
  margin-bottom: 30px;
}

.swiper-pagination {
  bottom: 6px;
}

.swiper-slide .card {
  border: 0;
}

.swiper-slide .card-img {
  padding: 0;
}

.swiper-slide .card-img img {
  width: 100%;
  height: 40vh;
}

.swiper-slide .card-title {
  margin: 0;
  font-weight: 700;
}

.swiper-slide .card-body {
  position: absolute;
  bottom: 20px;
  left: 40px;
  background-color: #fff;
}

.swiper-slide .card-body p {
  margin: 0;
}

@media (max-width: 979.98px) {
  .swiper-button-prev,
  .swiper-button-next {
    display: none;
  }
  .swiper-container {
    overflow: visible;
    /* used for displaying pagination outside of container */
  }
  .swiper-slide {
    width: 80%;
  }
  .swiper-slide .card-img img {
    height: auto;
  }
  .swiper-slide .card-body {
    position: static;
  }
  .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: -26px;
  }
  .swiper-pagination-bullet {
    width: 8px;
    height: 8px;
    border: 0;
  }
}

div.active:focus {
  outline: dotted 1px;
}

a:focus {
  color: #f16e00;
  outline: dotted 1px;
}

.close:hover, .close:focus {
  outline: dotted 1px;
}

.progress.progress-default[value]::-webkit-progress-value {
  background-color: #f16e00;
}

.progress.progress-default[value]::-moz-progress-bar {
  background-color: #f16e00;
}

.hero-container .frame {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 15%;
  margin: 30px;
}

.hero-container .caption {
  margin: 50px 30px;
}

.hero-container .category {
  font-size: 1.25rem;
  color: #f16e00;
}

/*# sourceMappingURL=boosted.css.map */