// stylelint-disable selector-no-qualifying-type
//

// back from accessibility plugin css
div.active:focus {
  outline: dotted 1px;
}

a:focus {
  color: theme-color("primary");
  outline: dotted 1px;
}

.close:hover,
.close:focus {
  outline: dotted 1px;
}

//
// Progress bar
//

.progress.progress-default {
  &[value]::-webkit-progress-value {
    background-color: theme-color("primary");
  }

  &[value]::-moz-progress-bar {
    background-color: theme-color("primary");
  }
}

.hero-container .frame {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 15%;
  margin: ($grid-gutter-width * 1.5);
}

.hero-container .caption {
  margin: ($grid-gutter-width * 2.5) ($grid-gutter-width * 1.5);
}

.hero-container .category {
  font-size: 1.25rem;
  color: theme-color("primary");
}

